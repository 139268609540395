import React from "react";

import {
  Grid,
  Typography,
  Box,
  // Customizable Area Start
  Drawer
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles";
import { penData, arrow, up_arrow, cart, truck, rupe, list, account, navData } from "./assets";
import GroupData from "../src/GroupData.web";
import UserNavbar from "../../../components/src/UserNavbar.web";
import Navbar from "../../../components/src/Navbar.web";
import ProjectData from "../src/ProjectData.web";
import SideBar from "../../../components/src/SideBar.web"


const MainContent = styled("div")(({ theme }) => ({
    borderRight:"1px solid #E1E7EF",
    cursor:"default",
    height:"100%",
    display:"flex",
    position: "relative",
    flexDirection:"column",
    "& .font-txt": {
      fontSize:"16px",
      fontWeight:400,
    },
    "& .type-txt": {
      fontSize:"14px",
      fontWeight:400,
      color:"#272727"
    },
    "& .type-txt-select": {
      fontSize:"14px",
      fontWeight:700,
      color:"#272727"
    },
    "& .arrow": {
      height:"6px"
    },
    "& .navigation-arrow":{
      position: "absolute",
      width: "24px",
      height: "24px",
      top: "50%",
      right: -12,
      transform: "translateY(-50%)",
      border:"1px solid #E2E8F0",
      borderRadius:"4px",
      "@media (max-width:900px)": {
        right: 0,
      },
  
    }
  }))
// Customizable Area End

import UserCategoriesController, {
  Props,
} from "./UserCategoriesController";
import Catagories from "./Catagories.web";

const catalogueItems = ["Categories", "Products", "Pricing", "Terms & Notes", "Delivery Challan"]

export default class UserCategories extends UserCategoriesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
sidebarContent =() => {
  return(
  <MainContent>
  <img src={navData} alt="navData" className="navigation-arrow"/>
  <Box style={webStyle.mainContent}>
      <Box style={webStyle.singleBox}>
        <Box><img src={penData} /></Box>
        <Box className="font-txt">Quotations</Box>
      </Box>
      <Box>
        <img src={arrow} alt="arrow" className="arrow"/>
      </Box>
  </Box>
  <Box style={webStyle.mainContent}>
    <Box style={webStyle.singleBox}>
      <Box><img src={cart} /></Box>
      <Box className="font-txt">Purchases</Box>
    </Box>
    <Box>
      <img src={arrow} alt="arrow" className="arrow"/>
    </Box>
  </Box>   
  <Box style={webStyle.mainContent}>
    <Box style={webStyle.singleBox}>
      <Box><img src={truck} /></Box>
      <Box className="font-txt">Delivery</Box>
    </Box>
    <Box>
      <img src={arrow} alt="arrow" className="arrow"/>
    </Box>
  </Box>  
  <Box style={webStyle.mainContent}>
    <Box style={webStyle.singleBox}>
      <Box><img src={rupe} /></Box>
      <Box className="font-txt">Payments</Box>
    </Box>
    <Box>
      <img src={arrow} alt="arrow" className="arrow"/>
    </Box>
  </Box>  
  <Box style={webStyle.toggleBox} onClick={()=>this.handleToggle("Catalogue")}>
    <Box style={webStyle.singleBox}>
      <Box><img src={list} /></Box>
      <Box className="font-txt" style={{ fontWeight: this.state.openDialog ==="Catalogue"?700:undefined}}>Catalogue</Box>
    </Box>
    <Box>
      {this.state.openDialog ==="Catalogue"? (<img src={up_arrow} style={{ cursor:"pointer"}} alt="up_arrow" className="arrow"/>) : (<img src={arrow} style={{ cursor:"pointer"}} alt="arrow" className="arrow"/>)}
    </Box>
  </Box> 
  {this.state.openDialog ==="Catalogue"&& (
    <Box style={{ marginLeft: "67px", cursor:"pointer"}}>
      {catalogueItems.map((item) =>
        <Typography data-test-id={`${item}-btn`} className={this.state.activeComponent === item ? "type-txt-select" : "type-txt"} onClick={() => this.handleSidebarItemClick(item)}>{item}</Typography>
      )}
    </Box>
  )}  
  <Box data-test-id="group-btn" style={webStyle.toggleBox} onClick={()=>this.handleToggle("Group Name")} >
    <Box style={webStyle.singleBox}>
      <Box><img src={account} /></Box>
      <Box className="font-txt" style={{ fontWeight: this.state.openDialog ==="Group Name"?700:undefined}}>Group Name</Box>
    </Box>
    <Box>
      {this.state.openDialog ==="Group Name"? (<img src={up_arrow} style={{ cursor:"pointer"}} alt="up_arrow" className="arrow"/>) : (<img src={arrow} style={{ cursor:"pointer"}} alt="arrow" className="arrow"/>)}
    </Box>
  </Box>    
  {this.state.openDialog ==="Group Name"&& (
    <Box style={{ marginLeft: "67px", cursor:"pointer"}}>
      <Typography data-test-id="sidebar-btn" className={this.state.activeComponent === "Company" ? "type-txt-select" : "type-txt"} onClick={() => this.handleSidebarItemClick('Company')}>Company</Typography>
      <Typography data-test-id="sidebar-btn-project" className={this.state.activeComponent === "Project" ? "type-txt-select" : "type-txt"} onClick={() => this.handleSidebarItemClick('Project')} style={{ marginTop:"10px"}}>Project</Typography>
    </Box>
  )}
</MainContent> )

}
  // Customizable Area End

  render() { 
   
    return (
      // Customizable Area Start
        <Box>
          <Navbar { ...this.props } />       
              <SideBar navigation={this.props.navigation} mycart={true}>
              {this.state.activeComponent === 'Company' && <GroupData/>}
              </SideBar>
        </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CategoryMenu = styled(Box)({
  display: "none",
  "@media (max-width: 600px)": {
    display: "block !important",
  },
  "@media only screen and (min-width: 600px) and (max-width: 900px) ": {
    display: "block !important",
  },
});
const CategoryBLock = styled(Box)({
  display: "block",

  height: "100%",
  marginRight: "5px",
  borderRight: "1px solid grey",
  "@media (max-width: 600px)": {
    display: "none !important",
  },
  "@media only screen and (min-width: 600px) and (max-width: 900px) ": {
    display: "none !important",
  },
});
const webStyle = {
  singleBox:{display: "flex", gap: "15px" }, 
  toggleBox:{display:"flex", alignItems:"center", justifyContent:"space-between", padding:"17px", cursor:"pointer",backgroundColor:"#f4f4f4", margin: "19px", border:"8px"},
  mainContent:{ display:"flex", alignItems:"center", justifyContent:"space-between", padding:"17px", margin: "19px"},
  mainWrapper: {
    display: "flex",
    fontFamily: "Inter",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End