import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import React from "react";
interface IndustryItem {
  id: string;
  type: string;
  attributes: {
    [x: string]: any;
    application_group_name:string
  };
}
// Customizable Area End

export const configJSON = require("./config");



export interface Props {
  // Customizable Area Start
  children?: React.ReactNode;
  navigation: any;
  id: string;
  history: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
 
  selectedFile:any;
  openDialog: boolean;
  alertMessage: string;
  industryData: IndustryItem[];
  anchorEl: HTMLElement | null;
  totalResults2: number;
  resultperPage2 : number;
  currentPage: number;
  selectedIndustryId:string;
  IndustryId:any;
  applicationGroup:any;
  applicationame:any;
  industry:any
  industryname:any;
  token: string;

  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ApplicationProductController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),      
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];


    this.state = {
      // Customizable Area Start
      industryData: [],
      IndustryId:0,
      applicationame:"",
      alertMessage: "",
      applicationGroup:"",
      industryname:"",
      openDialog: false,
      anchorEl: null,
      selectedFile:"",
      industry: "",
      selectedIndustryId:"",
      token: "",
      totalResults2: 0, 
      resultperPage2: 12, 
      currentPage: 1 ,
     
      // Customizable Area End

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as unknown as IBlock, this.subScribedMessages);    // Customizable Area Start  
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start  
    await super.componentDidMount?.();
    const Industry=localStorage.getItem('industryname')
    this.setState({industryname:Industry})
    const applicationGroup=localStorage.getItem('application_group');
    this.setState({applicationGroup:applicationGroup});
    const applicationame=localStorage.getItem('application_name');
    this.setState({applicationame});
    // Customizable Area End
  }

  handleNavigateProduct = (route?: string) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
  }

  navigateToProduct = (path:string)=> {
  window.location.href = path;
  }
  
   
  // Customizable Area End
}
