//@ts-nocheck

import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  Checkbox,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, styled} from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/VisibilityOutlined";
import ArrowDown from "@material-ui/icons/KeyboardArrowDownOutlined";
import Check from "@material-ui/icons/Check";
import ArrowRight from "@material-ui/icons/ArrowRight";
import BoltIcon from '@material-ui/icons/OfflineBoltOutlined';
import AddShopping from '@material-ui/icons/AddShoppingCartOutlined';
import INbox from '@material-ui/icons/InboxOutlined';
import History from '@material-ui/icons/HistoryOutlined';
import Planet from '@material-ui/icons/PublicOutlined';
import Truck from '@material-ui/icons/LocalShippingOutlined';
import ArrowForward from '@material-ui/icons/KeyboardArrowRight';
import Navbar from "../../../components/src/Navbar.web";
import Footer from "../../../components/src/Footer.web";
import {buttonIocn, callIcon, Done, Downarrow, InstantIcon, lead, location, mainIcon, parcel, Regional, transport, view, zoom} from "./assets"

export const ProductBlock = styled("div")(({ theme }) => ({
  "& .tableEye":{
    width:"12px"
  },
  "& .viewText":{
    fontWeight:400, 
    fontSize:"12px",
    display:"flex",
    cursor:"pointer"
  },
  "& .variationTh":{
    whiteSpace:"nowrap",
    padding:"15px"
  },
  "& .mainPhoto-container":{
    position:"relative",
    width:"386px",
    "@media (max-width:1200px)": {
      width:"100%",
    },
    "@media (max-width:900px)": {
      width:"90vw",
      margin:"0 auto",
      display:"flex",
      justifyContent:"center"
    },
  },
  "& .mainPhoto__zoom":{
    position:"absolute",
    right:"11px",
    bottom:'11px',
    cursor:"pointer"
  },
  "& .currentWay":{
    fontWeight:400,
  },
  "& .globalContainer":{
    padding: "0px 90px",
    "@media (max-width:1200px)": {
      padding: "0px 20px",
    },
    "@media (max-width:900px)": {
      padding: "0px 10px",
    },
  },
  "& .navMenu":{
    "@media (max-width:1200px)": {
      marginLeft:"0px",
      padding:"0px 20px"
    },
    "@media (max-width:900px)": {
      marginLeft:"0px",
      padding:"0px 10px"
    },
  },
  "& .notMainPhoto-container":{
    width:'386px',
    "@media (max-width:1200px)": {
      width: "100%"
    },
    "@media (max-width:900px)": {
      width:'386px'
    },
  },
  "& .notMainPhoto":{
    width: "90px",
    cursor:"pointer",
    "@media (max-width:1200px)": {
      width: "60px"
    },
    "@media (max-width:900px)": {
      width: "70px"
    },
  },
  "& .MuiGrid-grid-md-2":{
    flexBasis:"auto"
  },
  "& .documentsTable":{
    width:"386px",
    "@media (max-width:1200px)": {
      width:"90%",
    },
    "@media (max-width:900px)": {
      width:"80vw",
    },
  },
  "& .featureTableText":{
    fontFamily: 'Inter',
    fontSize: "12px",
    fontWeight: 400,
    color:"#9095A1",
    lineHeight:'22px'
  },
  "& .Variations__mainText":{
    fontFamily: 'Inter',
    fontSize: "14px",
    fontWeight: 700,
    color:"#323743",
    lineHeight:'22px'
  },
  "& .Variations__text":{
    fontFamily: 'Inter',
    fontSize: "14px",
    fontWeight: 400,
    color:"#171A1F",
    lineHeight:"26px"
  },
  "& .MuiCheckbox-root":{
    color: "#565D6D",
    '&.Mui-checked': {
      color: "#E55C00",
    },
  },
  "& .brandTableBody":{
    display: 'block',
    height:"160px",
    overflowY:"scroll",
    scrollbarColor:"#BDC1CA transparent",
    scrollbarWidth:"thin",
    scrollbarHeight : "255px"
  },
  "& .brandTableBody::-webkit-scrollbar-track":{
    backgroundColor:"blue"
  },
  "& .brandTableHead":{
    display:'block'
  },
  "& .tableBrand-Brand":{
    width:"90px",
    padding:"15px",
    textAlign:"center"
  },
  "& .tableBrand-Name":{
    width:"150px",
    padding:"15px",
    textAlign:"center",
    whiteSpace:"nowrap"
  },
  "& .tableBrand-SKU":{
    width:"115px",
    textAlign:"center"
  },
  "& .tableBrand-arrow":{
    width:"50px",
    textAlign:"center",
    cursor:"pointer"
  },
  "& .tableBrand-checkbox":{
    width:"45px",
    textAlign:"center"
  },
  "& .quote":{
    marginLeft:"50px",
    "@media (max-width:1200px)": {
      marginLeft:"5px"
    },
    "@media (max-width:900px)": {
      marginTop:"20px",
      marginLeft:"0px"
    },
  },
  "& .request":{
    marginLeft:"50px",
    "@media (max-width:1200px)": {
      marginLeft:"5px"
    },
    "@media (max-width:900px)": {
      marginLeft:"0px"
    },
  },
  "& .requestSample":{
    fontSize: "16px",
    fontWeight: 700,
    color:"#323743",
    lineHeight : "38px"
  },
  "& .noteText":{
    fontFamily: 'Inter',
    fontSize: "11px",
    fontWeight: 400,
    color:"#9095A1",
    lineHeight:"26px",
    fontStyle:"italic"
  },
  "& .noteTextMain":{
    fontFamily: 'Inter',
    fontSize: "11px",
    fontWeight: 400,
    color:"#323743",
    lineHeight:"26px",
    fontStyle:"normal",
  },
  "& .mainTextDescription":{
    fontFamily: 'Inter',
    fontSize: "12px",
    fontWeight: 400,
    color:"#171A1F",
    lineHeight:"24px",
  },
  "& .seccondaryTextDescription":{
    fontFamily: 'Inter',
    fontSize: "12px",
    fontWeight: 400,
    color:"#565D6D",
    lineHeight:"24px",
  },
  "& .specificationMainText":{
    fontFamily: 'Inter',
    fontSize: "24px",
    fontWeight: 600,
    color:"#181A1E",
    lineHeight:"30px",
    marginBottom:"24px",
    marginTop : "25px"
  },
  "& .specTableRow:nth-child(odd)":{
    backgroundColor:"#FAFAFB"
  },
  "& .certTable":{
    width:"100%",
    fontFamily: 'Inter',
    fontSize: "16px",
    fontWeight: 500,
    lineHeight:"20px",
    color:"#1E293B",
    marginBottom:"20px",
    border:"1px solid #DEDFE3",
    overflow:"hidden",
    borderRadius:"8px"
  },
  "& .specTableRow__td-text":{
    width:"90%",
    padding:"20px"
  },
  "& .gridTableSpec":{
    "@media (min-width:900px)": {
      maxWidth:"48%",
      flexBasis:"48%"
    },
  },
  "& .specTableRow__td-eye":{
    width:"90%",
    padding:"20px"
  },
  "& .certTableRow:nth-child(odd)":{
    backgroundColor:"#FAFAFB"
  },
  "& .certificationEye":{
    cursor:"pointer"
  },
  "& .quantityNumberArrow":{
    cursor:"pointer"
  }
}))
const theme = createTheme({
  cursor:"default",
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiTableCell: {
      root: {
        padding: '0px',
      },
    },
  },
});

const featureList = [
  { label: 'Brand Name', key: 'brand_name' },
  { label: 'GSM', key: 'gsm' },
  { label: 'No. of Sheets', key: 'no_of_sheets' },
  { label: 'No. of Ream', key: 'no_of_ream' },
  { label: 'Ideal For', key: 'ideal_for' }
];

const dummyBrandTable = [
  {
    brand:"Finolex",
    name:"ELBOW 90° PLAIN",
    sku:"2025000"
  },
  {
    brand:"Paras",
    name:"Elbow 90 Plain",
    sku:"675427846"
  },
  {
    brand:"Astral",
    name:"Elbow 90 Plain",
    sku:"675427846"
  },
  {
    brand:"Finolex",
    name:"ELBOW 100 PLAIN",
    sku:"2025800"
  },
  {
    brand:"Paraska",
    name:"Elbow 98 Plain",
    sku:"675427246"
  },
  {
    brand:"Astrall",
    name:"Elbow 92 Plain",
    sku:"675427841"
  }
]
// Customizable Area End

import ProductDescriptionController, {
  Props,
  configJSON,
} from "./ProductDescriptionController";
import { dummyProduct } from "./assets";

export default class ProductDescription extends ProductDescriptionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.dummyImg = [dummyProduct, dummyProduct, dummyProduct, dummyProduct];
    // Customizable Area End
  }

  // Customizable Area Start
  buttons = [
    { id: 1, label: 'Description' },
    { id: 2, label: 'Specifications' },
    { id: 3, label: 'Ways of Application' },
    { id: 4, label: 'Use' },
    { id: 5, label: 'Certifications' },
    { id: 6, label: 'Standards' },
    { id: 7, label: 'Quality Tests' },
    { id: 8, label: 'New Button' }
  ];
  getButtonStyle(buttonId:number) {
    return this.state.pageDescription === buttonId 
      ? webStyle.descriptionButton__active 
      : webStyle.descriptionButton;
  }
  getRowBackgroundColor(index) {
    return index % 2 ? 'white' : '#FAFAFB';
  }

   getProductDetailsFromState(state) {
    const { productData, focusedImageIndex } = state;

    const images = productData?.data?.attributes?.images || [];
    const primaryImage = productData?.data?.attributes?.primary_image?.url;
    const { attributes } = productData?.data || {};
  
    return {
      images,
      primaryImage,
      attributes,
      focusedImageIndex,
    };
  }

  getRowBackgroundColor = (index) => {
    return index % 2 === 0 ? '#F7F7F9' : '#FFFFFF';
  };

  renderSpecificationSection = (title, data) => (
    <Box style={{ flex: '1' }}>
      <Typography className="specificationMainText">{title}</Typography>
      <Box style={{ border: '1px solid #DEDFE3', borderRadius: '12px', overflow: 'hidden' }}>
        {data && Object.entries(data).map(([key, value], index) => (
          <Box key={index} style={{ display: 'flex', backgroundColor: this.getRowBackgroundColor(index), height: '52px' }}>
            <Typography style={{ ...webStyle.specKey }}>{key}</Typography>
            <Typography style={webStyle.specValue}>{value}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );

  renderCertificationSection = (data) => (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', gap: 2 }}>
      {[0].map((_, idx) => (
        <Box key={idx} sx={{ marginBottom: 2, width: "49%" }}>
          <Box sx={{ border: '1px solid #DEDFE3', borderRadius: '12px', overflow: 'hidden' }}>
            {data?.map((item, index) => (
              <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 16px', height: '52px', backgroundColor: this.getRowBackgroundColor(index), fontSize: '14px' }}>
                <Box sx={{ flex: 1 }} style={{ ...webStyle.Certifiy }}>{item.name}</Box>
                <Box sx={{ padding: '0 8px' }}>
                  <img src={view} alt="view" style={{ width: '24px', height: '24px' }} />
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  );

  renderDescriptionSection = (text) => (
    <Box style={{ paddingTop: '25px' }}>
      <Typography className="mainTextDescription">{text}</Typography>
    </Box>
  );

  renderContent = () => {
    const { pageDescription, productData } = this.state;
    if (!productData?.data) return null;

    const { description, ways_of_appliation, use, standards, specifications, certificates, quality_tests } = productData.data.attributes;

    switch (pageDescription) {
      case 1:
        return this.renderDescriptionSection(description);
      case 2:
        return (
          <Box style={{ display: 'flex', justifyContent: 'space-evenly', gap: '30px', width: '100%' }}>
          <Box style={{ flex: '1 1 auto' }}>
            {this.renderSpecificationSection("Features", specifications)}
          </Box>
          <Box style={{ flex: '1 1 auto' }}>
            {this.renderSpecificationSection("General Properties", specifications)}
          </Box>
        </Box>
        );
      case 3:
        return this.renderDescriptionSection(ways_of_appliation);
      case 4:
        return this.renderDescriptionSection(use);
      case 5:
        return (
          <Box mt={4}>
            {this.renderCertificationSection(certificates)}
          </Box>
        );
      case 6:
        return this.renderDescriptionSection(standards);
      case 7:
        return (
          <Box mt={4}>
            {this.renderCertificationSection(quality_tests)}
          </Box>
        );
      default:
        return null;
    }
  };
  
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <ProductBlock>
        <Navbar history={this.props.history} {...this.props}/>
        <Box>
        <Box style={{display:'flex' , padding:'15px 48px 34px 55px' , gap:'15px 30px', justifyContent:'space-between', flexWrap:'wrap' }} >
        <Grid container direction="row" alignItems="center" style={webStyle.navMenu}  justifyContent="flex-start">
          <Grid item style={webStyle.navMenuItem} id="navigation"  xs={4} sm={4} md={2}>
            <span style={{...webStyle.topnav}}>Home</span>   
            <ArrowForward style={{...webStyle.arrowforward}} />          
          </Grid>
          
          <Grid item style={webStyle.navMenuItem} id="navigation" xs={4} sm={4} md={2}>
            <span style={{...webStyle.topnav}}>Plumbing</span>  
            <ArrowForward style={{...webStyle.arrowforward}} />          
          </Grid>  
               
          <Grid item style={webStyle.navMenuItem} id="navigation" xs={4} sm={4} md={2}>
            <span style={{...webStyle.topnav}}>Internal Plumbing Systems</span> 
            <ArrowForward style={{...webStyle.arrowforward}} />          
          </Grid>
          
          <Grid item style={webStyle.navMenuItem} id="navigation" className="currentWay" xs={4} sm={4} md={1}>
            <span> 90 degree UPVC plain Elbow </span>
          </Grid>
         
          
        </Grid>
          <Box>
          <Box item className="main-photo">
                <Box className="mainPhoto-container">
                  {(() => {
                    const { images, primaryImage, focusedImageIndex } = this.getProductDetailsFromState(this.state);

                    return (
                      <Box>
                        <img
                          src={images[focusedImageIndex]?.url || primaryImage }
                          alt="Product"
                          style={webStyle.mainPhoto}
                          id="mainPhoto"
                        />
                        <img src={zoom} alt="zoom" className="mainPhoto__zoom" />
                      </Box>
                    );
                  })()}
                </Box>

                <Box
                  item
                  style={{
                    margin: "20px 0",
                    display: 'flex',
                    marginTop: '8px',
                    gap: "10px"
                  }}
                  className="notMainPhoto-container"
                >
                  {(() => {
                    const { images } = this.getProductDetailsFromState(this.state);
                    return images.map((item, index) => (
                      <img
                        key={index}
                        src={item.url}
                        alt={`Product ${index + 1}`}
                        data-testId="product-image"
                        id="carousel"
                        style={webStyle.notMainPhoto}
                        className="notMainPhoto"
                        onClick={() => {
                          this.setState({ focusedImageIndex: index });
                        }}
                      />
                    ));
                  })()}
                </Box>
              </Box>
              <Box  >
                <Typography style={{...webStyle.documentsText, fontFamily : "Inter"}}>View Documents</Typography>
                <Box style={{marginTop:'12px'}}>
                <table style={webStyle.documentsTable} cellPadding={0} cellSpacing={0} className="documentsTable">
                  <tr>
                    <td style={webStyle.tableTd}>Technical Data Sheets (TDS)</td>
                      <td style={webStyle.tableTd} className="viewText">
                        <Box style={{ display: 'flex', gap: '4px', justifyContent:'center',alignItems:'center'}}>
                          <img src={view} alt="view" style={{ height:'12px', width:'12px',}}/>
                          <Typography style={webStyle.view}>View</Typography>
                        </Box>

                      </td>
                  </tr>
                  <tr>
                    <td style={webStyle.tableTd}>Brochure</td>
                    <td style={webStyle.tableTd} className="viewText">
                        <Box style={{ display: 'flex', gap: '4px', justifyContent:'center',alignItems:'center' }}>
                          <img src={view} alt="view"  style={{width:'12px', height:'12px'}} />
                          <Typography style={webStyle.view}>View</Typography>
                        </Box>

                      </td>
                  </tr>
                </table>
                </Box>
              </Box>
              
         
          </Box>
          <Box  style={{...webStyle.secondPart}}>
              
          <Box className="NameFeature">
  {(() => {
    const { attributes } = this.getProductDetailsFromState(this.state);

    return (
      <Box>
        <h2 style={webStyle.h2}>{attributes?.name}</h2>
        <Typography style={webStyle.h4}>
          <span style={webStyle.h4Main}>Description:</span>
          {attributes?.product_description}
        </Typography>
        <Typography style={webStyle.h4}>
          <span style={webStyle.h4Main}>UPID:</span>
          {attributes?.upid_name}
        </Typography>
        <Typography style={webStyle.h4}>
          <span style={webStyle.h4Main}>Selected Brand:</span>
          {attributes?.brand_name}
        </Typography>

        <Box>
          <Typography style={webStyle.h3}>Features</Typography>
          <Box>
            {featureList.map((feature) => {
              const featureValue = attributes?.[feature.key] || "";

              return (
                <Box key={feature.label} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                  <img src={Done} alt="done" style={{ width: '14px', height: '14px' }} />
                  <Typography className="featureTableText">
                    {`${feature.label} : ${featureValue}`}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    );
  })()}
</Box>
              <Box  className="Variations"  style={{marginTop:"24px"}}>
                <Typography className="Variations__mainText">Variations:</Typography>
                <div style={webStyle.subTitle}>Lorem:</div>
                <Box style={{display:'flex', gap:"10px" ,marginTop:'6px'}}>
                    <button style={webStyle.variationButton}>
                      <Typography style={webStyle.fontVariationButton}>
                        Variation 01
                      </Typography>
                    </button>
                    <button style={webStyle.variationButton}>
                      <Typography style={webStyle.fontVariationButton}>
                        Variation 02
                      </Typography>
                    </button>
                    <button style={webStyle.variationButton}>
                      <Typography style={webStyle.fontVariationButton}>
                        Variation 03
                      </Typography>
                    </button>
                    <button style={webStyle.variationButtonActive}>
                      <Typography style={webStyle.fontVariationButton}>
                        Variation 04
                      </Typography>
                    </button>
                </Box>
              </Box>
              <Box style={{ marginTop: '20px' }}>
                    <table style={{ ...webStyle.brandTable }}>
                      <thead style={{ display: 'block' ,}}>
                        <tr
                          style={{
                            display: 'flex',
                            width: '100%',
                            backgroundColor: '#f1f1f1',
                            height: "47px" ,
                            alignItems:'center'
                          }}
                        >
                          <th
                            style={{
                              ...webStyle.brandTableRow,
                            
                              flex: 2, 
                              
                            }}
                          >
                            Brand
                          </th>
                          <th
                            style={{
                              ...webStyle.brandTableRow,
                              flex: 2, 
                              textAlign: 'center',
                            }}
                          >
                            Product Brand Name
                          </th>
                          <th
                            style={{
                              ...webStyle.brandTableRow,
                              flex: 2,
                              textAlign: 'center',
                            }}
                          >
                            Brand SKU
                          </th>
                          <th
                            style={{
                              ...webStyle.brandTableRow,
                              flex: 1, 
                              textAlign: 'center',
                            }}
                          ></th>
                          <th
                            style={{
                              ...webStyle.brandTableRow,
                              flex: 1, 
                              textAlign: 'center',
                            }}
                          ></th>
                        </tr>
                      </thead>
                      <tbody
                        className="brandTableBody"
                        style={{
                          display: 'block',
                          height: '160px', 
                          overflowY: 'scroll',
                        }}
                      >
                        {dummyBrandTable.map((row, index) => (
                          <tr
                            key={index}
                            style={{
                              display: 'flex',
                              width: '100%',                              
                              backgroundColor:
                              this.state.hoverIndex === index ? 'rgba(130,130,170,0.1)' : 'transparent',
                            }}
                            onMouseEnter={() => this.setState({
                              hoverIndex :index})} 
                            onMouseLeave={() => this.setState({
                              hoverIndex :null})} 
                          >
                            <td
                              style={{
                                ...webStyle.brandTableCell,
                                flex: 2, 
                                textAlign: 'center',
                              }}
                            >
                              {row.brand}
                            </td>
                            <td
                              style={{
                                ...webStyle.brandTableCell,
                                flex: 2, 
                                textAlign: 'center',
                              }}
                            >
                              {row.name}
                            </td>
                            <td
                              style={{
                                ...webStyle.brandTableCell,
                                flex: 2, 
                                textAlign: 'center',
                              }}
                            >
                              {row.sku}
                            </td>
                            <td
                              style={{
                                ...webStyle.brandTableCell,
                                flex: 1,
                                textAlign: 'center',
                              }}
                            >
                              <ArrowRight />
                            </td>
                            <td
                              style={{
                                ...webStyle.brandTableCell,
                                flex: 1, 
                                textAlign: 'center',
                              }}
                            >
                             <Checkbox
                                  color="#FF6600"
                                  icon={<span style={{ width: '16px', height: '16px', borderRadius: '4px', border: '1px solid #DEE1E6', display: 'block' }} />}
                                  Check={<span style={{ width: '16px', height: '16px', borderRadius: '4px', border: '1px solid #DEE1E6', backgroundColor: '#000', display: 'block' }} />}
                                />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
  </Box>
          </Box>
       
          <Box style={{width:"378px"}}  >
  <Box style={{border:'1px solid #DEDFE3' , borderRadius:'12px' , padding:"23px 23px" }}>
    <Box style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>

      <Typography style={webStyle.getQoute}>Get a Quote</Typography>
      <button style={webStyle.quoteButton}>
        <img src={InstantIcon} alt="InstantIcon" />
        Instant Quote
      </button>
    </Box>

    <Typography style={webStyle.orderQuantityText}>Order Quantity</Typography>

    <Box >
      <Box container direction="column" justifyContent="space-evenly" style={webStyle.quantity}>
        <Box style={{
          display: 'flex',
          alignItems: "center"
        }}>
          <input style={webStyle.quantityInput} placeholder="Quantity"></input>
          <Box style={{display:"Flex", marginTop:"8px"}}>
            <Typography style={webStyle.quantityNomer}>No.</Typography>
            <img src={Downarrow} alt="Downarrow" />
          </Box>
        </Box>

        <Typography style={webStyle.h4ConvertMain}>
          Unit Conversion - <span style={webStyle.h4Convert}>1 bag = {this.state.productData?.data?.attributes?.unit_conversion } </span>
        </Typography>

        <Box style={{display:'flex',alignItems:"center", justifyContent:"flex-end" , gap:'4px'}}>
          <button style={webStyle.addQuotation}>
            <img src={buttonIocn} alt="buttonIocn" />
            Add for Quotation
          </button>
        </Box>
      </Box>

      <Box  style={webStyle.quantity}>
        <Box style={{display:'flex', alignItems:'start', gap:'14px'}}  >
          <img src={parcel} style={{height:"16px" , width:'16px', marginTop:'6px'}} />
          <Box >
            <Typography style={webStyle.quoteinfo1}>MOQ</Typography>
            <Typography style={webStyle.quoteInfoAdd}>
              {this.state.productData?.data?.attributes?.minimum_order_quantity } No.s
            </Typography>
          </Box>
        </Box>

        <Box  >
          <Box style={{ display: 'flex', alignItems: 'start', gap: '14px', marginTop: '4px' }} >
            <img src={lead} alt="lead" style={{ height: "16px", width: '16px', marginTop: '6px' }} />
            <div style={webStyle.quoteInfo}>
              <Typography style={webStyle.quoteinfo1}>Lead Time</Typography>
              <Typography style={webStyle.quoteInfoAdd}>
              Quote Required
              </Typography>
            </div>
          </Box>
        </Box>

        <Box  >
          <Box style={{...webStyle.quoteItem,display: 'flex' ,alignItems: 'start', gap: '14px'}}>
            <img src={Regional} alt="Regional" style={{ height: "16px", width: '16px', marginTop: '6px' }}/>
            <div style={webStyle.quoteInfo}>
              <Typography style={webStyle.quoteinfo1}>Regional Availability</Typography>
              <Typography style={webStyle.quoteInfoAdd}>
              Available for your Project
              </Typography>
            </div>
          </Box>
        </Box>

        <Box  >
          <Box style={{...webStyle.quoteItem,display: 'flex' ,alignItems: 'start', gap: '14px'}}>
            <img src={transport} alt="transport" style={{ height: "16px", width: '16px', marginTop: '6px' }} />
            <div style={webStyle.quoteInfo}>
              <Typography style={webStyle.quoteinfo1}>Transportation</Typography >
              <Typography  style={webStyle.quoteInfoAdd}>
              Quote Required
              </Typography>
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
    </Box>


    <Box style={webStyle.request} >
      <Typography className="requestSample">Request for a Sample</Typography>
      <button style={{...webStyle.requestButton, textTransform:'none'}}>
        Request Sample
      </button>
      <Typography className="noteText"><span className="noteTextMain">Note:</span> Sampling not available for this product.</Typography>
    </Box>
</Box>
              
       
       
          </Box>
        </Box>
          
        <Box container style={{ ...webStyle.description, overflowX: 'auto' }}>
  <Box style={{ display: 'flex', justifyContent: 'space-between' }} container direction="row" alignItems="center" justifyContent="center">
    {this.buttons.map((button) => (
      <React.Fragment key={button.id}>
        <button
          id={`button-${button.id}`}
          data-testId={`button-${button.id}`}
          style={this.getButtonStyle(button.id)}
          onClick={() => this.setState({ pageDescription: button.id })}
        >
          {button.label}
        </button>
        <div style={webStyle.separator}></div>
      </React.Fragment>
    ))}
  </Box>

  <Box>
  <Grid container direction="column">
        {this.renderContent()}
  </Grid>
</Box>

</Box>

        </ProductBlock>
 
 <Box
      style={{
        display: 'flex',
        flexWrap : "wrap",
        justifyContent: 'space-between',
        padding:"28px 58px 20px 58px"
      }}
    >
      <Box style={{width:"169px"}}>
        <Typography variant="h6" style={{...webStyle.Foothead}}>Services</Typography>
        <Typography  style={{ ...webStyle.FooterLink,display: 'block' , marginTop : "17px"}}>How it works</Typography>
        <Typography  style={{...webStyle.FooterLink, display: 'block',marginTop : "15px" }}>Work with us</Typography>
        <Typography  style={{...webStyle.FooterLink, display: 'block' ,marginTop : "15px"}}>About Us</Typography>
        <Typography  style={{...webStyle.FooterLink, display: 'block', marginTop : "15px" }}>Contact Us</Typography>
        <Typography  style={{...webStyle.FooterLink, display: 'block' ,marginTop : "15px"}}>Bulk Order</Typography>
      </Box>
      <Box style={{width:"169px", height: "192px"}}>
        <Typography variant="h6" style={{...webStyle.Foothead}}>Top Categories</Typography>
        <Typography  style={{ ...webStyle.FooterLink,display: 'block', marginTop : "17px" }}>Power and Tools</Typography>
        <Typography  style={{...webStyle.FooterLink, display: 'block' }}>Electrical</Typography>
        <Typography  style={{...webStyle.FooterLink, display: 'block' }}>Plywood and Laminates</Typography>
        <Typography  style={{ ...webStyle.FooterLink,display: 'block' }}>Safety</Typography>
        <Typography  style={{ ...webStyle.FooterLink,display: 'block' }}>Office Stationary</Typography>
        <Typography  style={{ ...webStyle.FooterLink,display: 'block' }}>Hardware</Typography>
        <Typography  style={{ ...webStyle.FooterLink,display: 'block' }}>Lighting and Fans</Typography>
        <Typography  style={{ ...webStyle.FooterLink,display: 'block' }}>Tiles</Typography>
      </Box>

      <Box>
        <Typography variant="h6" style={{...webStyle.Foothead}}>Cities</Typography>
        <Typography  color="inherit" style={{...webStyle.FooterLink, display: 'block', marginTop : "17px" }}>Lancaster</Typography>
        <Typography  color="inherit" style={{...webStyle.FooterLink, display: 'block', marginTop:"3px" }}>Castle Rock</Typography>
        <Typography  color="inherit" style={{...webStyle.FooterLink, display: 'block', marginTop:"3px" }}>Burleson</Typography>
        <Typography  color="inherit" style={{...webStyle.FooterLink, display: 'block' , marginTop:"3px"}}>Montclair</Typography>
        <Typography  color="inherit" style={{...webStyle.FooterLink, display: 'block' , marginTop:"3px"}}>Albuquerque</Typography>
        <Typography  color="inherit" style={{...webStyle.FooterLink, display: 'block', marginTop:"3px" }}>Appleton</Typography>
        <Typography  color="inherit" style={{...webStyle.FooterLink, display: 'block' , marginTop:"3px"}}>Prescott Valley</Typography>
        <Typography  color="inherit" style={{...webStyle.FooterLink, display: 'block' , marginTop:"3px"}}>Milford</Typography>

      </Box>

      <Box style={{height: "120px",width:"169px"}}>
        <Typography variant="h6" style={{...webStyle.Foothead}}>Top Categories</Typography>
        <Typography  style={{display: 'block', ...webStyle.FooterLink, marginTop : "17px" }}>Power and Tools</Typography>
        <Typography  style={{display: 'block',...webStyle.FooterLink,  }}>Electrical</Typography>
        <Typography  style={{display: 'block',...webStyle.FooterLink,  }}>Plywood and Laminates</Typography>
        <Typography  style={{display: 'block', ...webStyle.FooterLink, }}>Safety</Typography>
        <Typography  style={{display: 'block', ...webStyle.FooterLink, }}>Office Stationary</Typography>
        <Typography  style={{display: 'block', ...webStyle.FooterLink, }}>Hardware</Typography>
        <Typography  style={{display: 'block', ...webStyle.FooterLink, }}>Lighting and Fans</Typography>
        <Typography  style={{display: 'block', ...webStyle.FooterLink, }}>Tiles</Typography>
      </Box>

          <Box style={{  padding: "17px 34px 0px 0px", marginLeft: "80px" }}>
            <Typography variant="h6" style={{ ...webStyle.Foothead, marginTop: "17px" }}>Contact us</Typography>
            <Box style={{ display: 'flex', marginTop:"18px" }}><img src={location} alt="location" style={{ width: "20px", height: "20px" , margin: "auto 0px"}} /> <Typography style={{ ...webStyle.address,width: "206px" }}>Abc-123 Company Addressxyz
              Lorem ipsum dolor sit ame.</Typography></Box>
            <Box style={{ display: 'flex', marginTop:"20px" }}><img src={callIcon} alt="location" style={{ width: "20px", height: "20px" }} /> <Typography style={{  ...webStyle.address,width: "80px" }}>123456 598</Typography></Box>
            <Box style={{  display: 'flex', marginTop:"20px" }}><img src={mainIcon} alt="location" style={{ width: "20px", height: "20px" }} /> <Typography style={{  ...webStyle.address,width: "156px" }}>contact@company.com</Typography></Box>
            <Box>
            <Typography style={{...webStyle.copyRight}}>Copyrights 2023.All rights reserved</Typography>
          </Box>
          </Box>
         
    </Box>
   
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  certificate:{fontSize:'16px', fontWeight:500, lineHeight:'20px', padding:'20px 10px 20px 20px'},
  view:{fontSize:'12px', fontWeight:400,lineHeight:'20px'},
  subTitle:{fontWeight:400 , fontSize:'14px', lineHeight:'26px' },
  quoteinfo1:{fontWeight:700 , fontSize:'12px', lineHeight:'26px' , },
  mainWrapper: {
    display: "flex",
    fontFamily: "Inter",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  globalContainer:{
    marginTop:"20px",
  },
  tableEye:{
    width:"12px",
    height:"12px"
  },
  documentsTable: {
    borderRadius:"6px",
    border:"1px solid #DEDFE3",
    fontFamily: 'Inter',
    fontSize: "12px",
    fontWeight: 700,
    overflow:"hidden",
  },
  tableTd:{
    padding:"10px",
    border:"1px solid #DEDFE3",
    textAlign:"center",
    alignItems:'center',
    justifyContent:"center"
  },
  tableChild:{
    padding:"19px",
    borderTop:"1px solid",
    borderLeft:"1px solid",
    borderColor:"#9095A0",
    textAlign:"center"
  },
  arrowforward:{
    height : "16px",
    width : "16px"
  },
  tableChildLeftUp: {
    padding:"19px",
    textAlign:"center",
  },
  tableChildRightUp: {
    padding:"19px",
    borderLeft:"1px solid",
    borderColor:"#9095A0",
    textAlign:"center"
  },
  tableChildLeftDown: {
    padding:"19px",
    borderTop:"1px solid",
    borderColor:"#9095A0",
    textAlign:"center"
  },
  tableChildRightDown: {
    padding:"19px",
    borderTop:"1px solid",
    borderLeft:"1px solid",
    borderColor:"#9095A0",
    textAlign:"center"
  },
  firstCell: {
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px"
  },
  lastCell: {
    border: "1px solid",
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px"
  },
  copyRight:{
    fontFamily : "Inter",
    fontSize : "15px",
    fontWeight : 400,
    color : "#BDC1CA",
    lineHeight : "24px",
    marginTop : "56px"
  },
  variationButton: {
    cursor:"pointer",
    height:"35px",
    padding: "8px 12px",
    border: '1px solid',
    borderColor: "#F0F0F0",
    borderRadius:"9px",
    backgroundColor: "#FFFFFF",
    color:"#1E293B",
    "&:hover": {
      backgroundColor: "#EE6920",
      color: "#FFFFFF"
    },
    fontFamily: 'Inter',
  },
  fontVariationButton:{
    fontFamily: 'Inter',
    fontSize: "12px",
    fontWeight: 400,
  },
  variationButtonActive: {
    height:"35px",
    padding: "8px 12px",
    border: '1px solid',
    borderColor: "transparent",
    borderRadius:"9px",
    backgroundColor: "#FF6600",
    color: "#FFFFFF"
  },
  address:{
    fontFamily : "Inter",
    fontWeight : 400,
    fontSize : "14px",
    marginLeft: "6px",
    color:"#BDC1CA"
  },
  FooterLink:{
    color : "#BDC1CA",
    fontFamily : "Inter ",
    fontSize : "12px",
    fontWeight : "400",
    lineHeight : "24px"
  },
  brandTable:{
    border:"1px solid #DEDFE3",
    borderRadius:"12px",
    width : "468px"
  },
  Certifiy:{
    fontSize : "16px",
    fontWeight : 500,
    fontFamily : "Inter"
  },
  brandTableCell:{
    borderTop:"1px solid #DEE1E6",
    fontFamily: 'Inter',
    fontSize: "12px",
    fontWeight: 400,
    color:"#171A1F",
   lineHeight:'24px',
   alignItems: 'center',
   display: 'flex',
   justifyContent:'center'
  },
  topnav:{
    fontSize : "12px",
    fontWeight :700,
    fontFamily : "Inter"
  },
  brandTableRow:{
   lineHeight:'20px',
    fontFamily: 'Inter',
    fontSize: "12px",
    fontWeight: 500,
    color:"#323743",
    textAlign:'center',
  },
  quoteButton:{
    color:"#FFFFFF",
    backgroundColor:"#FF6600",
    fontFamily: 'Inter',
    fontSize: "12px",
    fontWeight: 400,
    borderRadius:"9px",
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    gap:'5px',
    borderColor:"transparent",
    height:"32px",
    width: "132px",
    whiteSpace:"nowrap",
    cursor:"pointer"
  },
  quote:{
    border:"1px solid",
    borderRadius:"8px",
    borderColor:"#DEDFE3",
    padding:"20px"
  },
  quantityInput:{
    width:"130px",
    height:"30px",
    backgroundColor:"#F3F4F6",
    borderRadius:"9px",
    alignItems:"center",
    borderColor:"transparent",
    fontFamily: 'Inter',
    fontSize: "12px",
    fontWeight: 400,
    cursor:"pointer",
    color:'#BDC1CA',
    lineHeight:'20px'
  },
  quantity:{
   
  },
  Foothead:{
    fontWeight : 700,
    fontSize : "14px",
    lineHeight : "30px"
  },
  quantityNomer:{
    color:"#FF6600",
    fontFamily: 'Inter',
    fontSize: "12px",
    fontWeight: 400,
    marginLeft:"15px",
    cursor:"pointer",
    lineHeight:'20px',
  },
  addQuotation:{
    color:"#FFFFFF",
    backgroundColor:"#FF6600",
    fontFamily: 'Inter',
    fontSize: "12px",
    fontWeight: 400,
    borderRadius:"9px",
    padding:"10px 8px",
    display:"flex",
    alignItems:"center",
    justifyContent:"space-around",
    borderColor:"transparent",
    marginTop:"8px",
    cursor:"pointer",
    height : "32px",
    lineHeight:'20px'
  },
  quoteInfo:{
    display: "flex",
    alignContent:"",
    flexDirection:"column",
    fontFamily: 'Inter',
    fontSize: "12px",
    fontWeight: 700,
    color:"#323743",
    lineHeight:"26px"
  },
  quoteItem:{
    marginTop:"16px"
  },
  request:{
    border:"1px solid",
    borderRadius:"12px",
    borderColor:"#DEDFE3",
    marginTop:"20px",
    padding:"5px 0px 17px 20px",
    fontFamily: 'Inter',
    fontSize: "14px",
    fontWeight: 400,
    backgroundColor : "#F8F9FA"
  },
  requestButton:{
    color:"#FFFFFF",
    backgroundColor:"#969697",
    borderRadius:"9px",
    marginTop:"8px",
    fontFamily: 'Inter',
    fontSize: "12px",
    fontWeight: 400,
    borderColor:"transparent",
    padding:"6px 0px",
    height:'32px',
    width:"118px",
    cursor:"pointer"
  },
  description:{
    border: "1px solid",
    borderRadius:"9px",
    marginTop:"60px",
    padding:"19px 24px 30px 24px",
    borderColor:"#DEDFE3",
    marginBottom:"60px",
    height:"auto",
    width : "92%",
    margin: "0px auto"
  },
  descriptionButton__active:{
    borderRadius:"12px",
    backgroundColor:"#FF6600",
    color:"#FFF",
    padding:"11px 43px",
    fontFamily: 'Inter',
    fontSize: "14px",
    fontWeight: 400,
    borderColor:"transparent",
    cursor:"pointer"
  },
  descriptionButton:{
    backgroundColor:"#FFF",
    color:"#FF6600",
    padding:"11px 38px",
    fontFamily: 'Inter',
    fontSize: "14px",
    fontWeight: 400,
    borderColor:"transparent",
    cursor:"pointer"
  },
  separator:{
    height:"22px",
    border:"1px solid",
    borderColor:"#FFEBDD",
  },
  textDescription:{
    marginTop:"25px",
    fontFamily: 'Inter',
    fontSize: "20px",
    fontWeight: 400,
    color:"#334155",
  },
  navMenu:{
    fontFamily: 'Inter',
    fontSize: "11px",
    fontWeight: 700,
    color:"#323743",
  },
  navMenuItem:{
    height:"19px",
    alignItems:"center",
    whiteSpace:"nowrap",
    display:"flex",
    cursor:"pointer"
  },
  notMainPhoto:{
    width:'90px',
    height:'85px',
    borderRadius:'9px',
    border:'1px solid #DEDFE3'
  },
  mainPhoto:{
    width:'386px',
    height:'347px',
    borderRadius:'12px',
    border:'1px solid #DEDFE3',
    backgroundColor:'#FFFFFF'
  },
  documentsText:{
    fontFamily : "Inter",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight:"38px",
    color:"#323743",
    marginTop:'5px'
  },
  secondPart:{
    fontFamily: 'Inter',
  },
  h2:{
    fontSize: "24px",
    fontWeight: 700,
    color:"#323743",
    margin:"4px 0px"
  },
  h4:{
    fontSize: "12px",
    fontWeight: 400,
    color:"#323743",
    margin:"5px 0px",
    lineHeight:'22px'
  },
  h4Main:{
    fontWeight: 700,
    fontSize:'12px',
    lineHeight:'22px'
  },
  h3:{
    fontSize: "12px",
    fontWeight: 700,
  },
  featureLabel:{
    fontSize: "16px",
    fontWeight: 500,
    color:"#9095A0"
  },
  getQoute:{
    fontSize: "16px",
    fontWeight: 700,
    color:"#171A1F",
  },
  orderQuantityText:{
    fontFamily: 'Inter',
    fontSize: "12px",
    fontWeight: 700,
    color:"#424856",
    lineHeight:'20px',
    marginTop:"14px"

  },
  h4Convert:{
    fontFamily: 'Inter',
    fontSize: "11px",
    fontWeight: 400,
    color:"#9095A1",
    margin:"0px",
    lineHeight:"26px"
  },
  h4ConvertMain:{
    color:"#1E293B",
    fontWeight: 700,
    fontSize:'11px',
    lineHeight:'26px'
  },
  quoteInfoAdd:{
    color:"#9095A1",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight:'26px'
  },
  specTable:{
    width:"100%",
    fontFamily: 'Inter',
    fontSize: "16px",
    fontWeight: 500,
    lineHeight:"20px",
    color:"#666666",
    marginBottom:"20px",
    border:"1px solid #DEDFE3",
    overflow:"hidden",
    borderRadius:"8px",
    marginTop : "20px"
  },
  spec:{
    height:"auto",
   
  },
  specKey:{
    color:"#1E293B",
    fontFamily: 'Inter',
    padding:"20px 10px 20px 20px",
    width:"270px",
    fontSize:'16px',
    lineHeight:'20px',
    fontWeight:500

  },
  specValue:{
    fontSize:'16px',
    lineHeight:'20px',
    fontWeight:500,
    fontFamily: 'Inter',
    height:"50px",
    width:"60%",
    color:"#666666",
    padding:"20px 10px 20px 24px",

  }
};
// Customizable Area End
