import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {Message} from "../../../framework/src/Message"
import { getStorageData } from "../../../framework/src/Utilities";
import { ChangeEvent } from "react";

// Customizable Area Start
interface IndustryItem {
  id: string;
  type: string;
  attributes: {
    name: string;
  };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  history: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  errors:any;
  anchorEl: HTMLElement | null;
  value: number | 0;
  totalResults: number;
  industryData: IndustryItem[];
  resultperPage : number;
  currentPage: number;
  brandName: string;
  industrySector: string;
  brandTagline: string;
  brandDescription: string;
  selectedFile: File | null;
  qualityFile1:File;
  qualityFile1Name:string;
  regions:any;
  certificateName:string;
  legalName:string;
  taxNumber:string;
  licenses:any;
  street:string;
  city:string;
  selectedCity: any;
  state:string;
  country:string;
  zipcode:string;
  primaryConName:string;
  primaryPhoneNum:string;
  alternativeContact:string;
  customerSupport:string;
  cities:any;
  certificates:any;
  emailAddress:string;
  region:string;
  isAutocompleteOpen: boolean;
  openAutocomplete:any,
  yourChips: any,
  [key: string]: any;

  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddbrandsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addBrandCall:string|Message=''
  getIndustryApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),      
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
   // Customizable Area Start
   errors:{},
   yourChips: [],
   openAutocomplete: [false],
   selectedCity:"",
   industryData:[],
   certificates: [{ certificateName: "", qualityFile2: null, qualityFile2Name: "" }],
   licenses: [{ licenseName: "", qualityFile3: null, qualityFile3Name: "" }],
   qualityFile1Name:"",
   anchorEl: null,
   value: 0,
   totalResults: 140, 
   resultperPage: 12, 
   currentPage: 1 ,
   brandName: "",
   industrySector: "",
   regions: ["North", "South", "East", "West"],
   brandTagline: "",
   brandDescription: "",
   selectedFile: null,
   qualityFile1:new File([], ""),
   qualityFile2:new File([], ""),
   qualityFile3:new File([], ""),
   certificateName:"",
   cities: [{ cityName: "" }], 
   legalName:"",
   taxNumber:"",
   street:"",
   city:"",
   state:"",
   country:"",
   zipcode:"",
   primaryConName:"",
   primaryPhoneNum:"",
   alternativeContact:"",
   customerSupport:"",
   emailAddress:"",
   region:"",
   isAutocompleteOpen: false,
  // Customizable Area End
  
 // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start  
    // Customizable Area End
  }

  async componentDidMount() {
     // Customizable Area Start  
    await super.componentDidMount(); 
    this.getIndustry();
// Customizable Area End
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
  
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
  
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      
      if (apiRequestCallId === this.addBrandCall) {
        this.handleAddIndustryResponse(responseJson);
      }
       else if (apiRequestCallId === this.getIndustryApiCallId) {
          this.handleGetIndustryResponse(responseJson);
        }
       
      }
  }

  handleAddIndustryResponse(responseJson: any) {
    if (responseJson?.data) {
      const brandCatalogueRoute = '/Brands'; 
      window.location.href = brandCatalogueRoute; 
      this.getIndustry(); 
    }
  }

  validateFields = () => {
    const errors: Record<string, string> = {};
    const { brandName, industrySector, brandTagline, qualityFile1, brandDescription } = this.state;
  
    if (!brandName) errors.brandName = "Brand Name is required.";
    if (!industrySector) errors.industrySector = "Industry / Sector is required.";
    if (!brandTagline) errors.brandTagline = "Brand Tagline is required.";
    if (!qualityFile1) errors.qualityFile1 = "Brand Logo is required.";
    if (!brandDescription) errors.brandDescription = "Brand Description is required.";
  
    this.setState({ errors });
  
    return Object.keys(errors).length === 0; 
  };

  validateBusinessInformationFields = () => {
    const errors: Record<string, string> = {};
    const {
      legalName,
      taxNumber,
      street,
      city,
      state,
      country,
      zipcode,
    } = this.state;

    if (!legalName) errors.legalName = "Legal Business Name is required.";
    if (!taxNumber) errors.taxNumber = "Tax Identification Number is required.";
    if (!street) errors.street = "Street is required.";
    if (!city) errors.city = "City is required.";
    if (!state) errors.state = "State is required.";
    if (!country) errors.country = "Country is required.";

    this.setState({ errors });

    return Object.keys(errors).length === 0; 
  };

  validateBrandConDetailsFields = (): boolean => {
    const errors: Record<string, string> = {};
    const {
      primaryConName,
      primaryPhoneNum,
      alternativeContact,
      customerSupport,
      emailAddress,
    } = this.state;
  
    if (!primaryConName?.trim()) errors.primaryConName = "Primary Contact Name is required.";
    if (!primaryPhoneNum?.trim()) errors.primaryPhoneNum = "Primary Phone Number is required.";
    else if (!/^\d{10}$/.test(primaryPhoneNum)) {
      errors.primaryPhoneNum = "Please enter a valid 10-digit phone number.";
    }
    if (!alternativeContact?.trim()) errors.alternativeContact = "Alternative Contact Information is required.";
    if (!customerSupport?.trim()) errors.customerSupport = "Customer Support Contact Details are required.";
    if (!emailAddress?.trim()) errors.emailAddress = "Email Address is required.";
    else if (!/\S+@\S+\.\S+/.test(emailAddress)) errors.emailAddress = "Invalid Email Address format.";
  
    this.setState({ errors });
  
    return Object.keys(errors).length === 0; 
  };

  handleSaveBrandConDetails = () => {
    if (this.validateBrandConDetailsFields()) {
      this.setState({ value: 2 })
      console.log("Validation passed. Saving...");
    } else {
      console.log("Validation failed. Fix errors.");
    }
  };

  validateOtherTabsFields = (): boolean => {
    const errors: Record<string, string> = {};
    const { selectedCity, region } = this.state;
    this.setState({ errors });
    return Object.keys(errors).length === 0; 
  };
  
  handleSaveOtherTabs = () => {
    if (this.validateOtherTabsFields()) {
      this.setState({ value: 3 })
      console.log("Validation passed. Saving...");
    } else {
      console.log("Validation failed. Fix errors.");
    }
  };
  
  handleSave = () => {
    if (this.validateBusinessInformationFields()) {
      this.setState({ value: 4 })  
    } else {
    }
  };

  validateBrandDocuments = () => {
    const errors: Record<string, string> = {};
    const { certificates } = this.state;
    certificates.forEach((certificate:any, index:any) => {
      if (!certificate.certificateName) {
        errors[`certificates[${index}].certificateName`] = "Certificate Name is required.";
      }
      if (!certificate.qualityFile2 && !certificate.qualityFile2Name) {
        errors[`certificates[${index}].qualityFile2`] = "At least one document upload is required.";
      }
    });
    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };

  handleSubmit = () => {
  if (this.validateFields()) {
    this.setState({ value: 1 })
  }
};

  handleGetIndustryResponse(responseJson: any) {
    if (responseJson?.data) {
      const { count, items, page } = responseJson.meta?.pagy || {}; 
      this.setState({
        industryData: responseJson.data, 
        totalResults: count || 0,       
        resultperPage: items || 12,    
        currentPage: page || 1,       
      });
    }
  }
  postAddBrandApi = async () => {
      const header = {
        token: await getStorageData("authToken"),
      };
      const formData = new FormData();
      formData.append("brand[name]", this.state.brandName || "");
      formData.append("brand[tagline]", this.state.brandTagline || "");
      formData.append("brand[description]", this.state.brandDescription || "");
      formData.append("brand[industry_sector_id]", this.state.industrySector || "");
      formData.append("brand[primary_contact_name]", this.state.primaryConName || "");
      formData.append("brand[primary_phone_number]", this.state.primaryPhoneNum || "");
      formData.append("brand[primary_contact_number]", this.state.primaryPhoneNum || "");
      formData.append("brand[altranative_contact_number]", this.state.alternativeContact || "");
      formData.append("brand[customer_support_contact_details]", this.state.customerSupport || "");
      formData.append("brand[email]", this.state.emailAddress || "");
      formData.append("brand[legal_bussness_name]", this.state.legalName || "");
      formData.append("brand[tax_identification_number]", this.state.taxNumber || "");
      formData.append("brand[street]", this.state.street || "");
      formData.append("brand[city]", this.state.city || "");
      formData.append("brand[state]", this.state.state || "");
      formData.append("brand[country]", this.state.country || "");
      formData.append("brand[zipcode]", this.state.zipcode || "");
      formData.append("brand[region][][city]", this.state.city || "new ");
      formData.append("brand[region][][region][]", this.state.region || "north");   
      this.state.certificates.forEach((certificate:any, index:any) => {
        formData.append(`brand[brand_certificates_attributes][${index}][document_type]`, "test");
        formData.append(`brand[brand_certificates_attributes][${index}][certificate_file]`, certificate.qualityFile2 || null);
        formData.append(`brand[brand_certificates_attributes][${index}][certificate_name]`, certificate.certificateName || "");
      })
      this.state.licenses.forEach((license:any, index:any) => {
        formData.append(`brand[brand_certificates_attributes][${index}][document_type]`, "test");
        formData.append(`brand[brand_certificates_attributes][${index}][certificate_file]`, license.qualityFile3 || "");
        formData.append(`brand[brand_certificates_attributes][${index}][certificate_name]`, license.licenseName || "AAC Blocks 4");
      });
      
      formData.append("brand[image]", this.state.qualityFile1 || null);
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.addBrandCall = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addBrandApiEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
  
      return true;
  };

  addCityField = () => {
    this.setState((prevState) => ({
      cities: [...prevState.cities, { cityName: "" }],
      openAutocomplete: [...prevState.openAutocomplete, false],
    }));
  };
  
  handleCityChange = (event:any, value:any , index:any) => {
    const updatedCities = [...this.state.cities];
    updatedCities[index].cityName = value;

    this.setState({ cities: updatedCities });
  };

  addCertificateField = () => {
    this.setState((prevState) => ({
      certificates: [
        ...prevState.certificates,
        { certificateName: "", qualityFile2: null, qualityFile2Name: "" }, 
      ],
    }));
  };

  addLicenseField = () => {
    this.setState((prevState) => ({
      licenses: [...prevState.licenses, { licenseName: "", qualityFile3: null, qualityFile3Name: "" }],
    }));
  };
  
  handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: string,
    index?: number
  ): void => {
    const { value } = event.target;
  
    this.setState((prevState) => {
      let updatedState = { ...prevState };
  
      if (index !== undefined && Array.isArray(prevState.certificates)) {
        const updatedCertificates = [...prevState.certificates];
        updatedCertificates[index] = {
          ...updatedCertificates[index],
          [fieldName]: value,
        };
  
        updatedState = {
          ...prevState,
          certificates: updatedCertificates,
          errors: { ...prevState.errors, [`certificates[${index}].${fieldName}`]: undefined },
        };
      } else {
        updatedState = {
          ...prevState,
          [fieldName]: value,
          errors: { ...prevState.errors, [fieldName]: undefined },
        };
      }
  
      return updatedState;
    });
  };
  
  handleFileChange = (
    event: ChangeEvent<HTMLInputElement>,
    fieldName: string,
    index?: number,
    type?: "certificates" | "licenses"
  ) => {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
  
    const file = event.target.files[0];
  
    if (file) {
      this.setState((prevState) => {
        let updatedState = { ...prevState }
        if (type && index !== undefined) {
          const updatedItems = [...prevState[type]];
          updatedItems[index] = {
            ...updatedItems[index],
            [fieldName]: file,
            [`${fieldName}Name`]: file.name,
          };
          updatedState = {
            ...prevState,
            [type]: updatedItems,
            errors: { ...prevState.errors, [`${type}[${index}].${fieldName}`]: undefined },
          };
        } else {
          updatedState = {
            ...prevState,
            [fieldName]: file,
            [`${fieldName}Name`]: file.name,
            errors: { ...prevState.errors, [fieldName]: undefined },
          };
        }
        return updatedState;
      });
      event.target.value = "";
    }
  };
  
  getIndustry = async () => {
    const header = {
      "Content-Type": configJSON.ContentType,
      token: await getStorageData("authToken"),
    };
  
    const queryParams = `?page=${this.state.currentPage}&items=${this.state.resultperPage}`;
    const apiEndpoint = `${configJSON.industryGetAPIEndPoint}${queryParams}`
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getIndustryApiCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  handleTabChange = (newValue: number): void => {
    this.setState({ value: newValue});
  }

  handleRegionSelect = (event:any , value:any) => {
    this.setState({ yourChips: value }); 
  };

  handleDeleteChip = (chipToDelete:any) => {
    this.setState((prevState) => ({
      yourChips: prevState.yourChips.filter((chip:any) => chip !== chipToDelete),
    }));
  };

  handleChanges = (event:  React.ChangeEvent<{
    name?: string;
    value: unknown;
}>, fieldName: string) => {
    this.setState({ [fieldName]: event.target.value });
  };
  handleAutocompleteToggle = (index:any) => {
    this.setState((prevState) => {
      const updatedOpenState = [...prevState.openAutocomplete];
      updatedOpenState[index] = !updatedOpenState[index]; 
      return { openAutocomplete: updatedOpenState };
    });
  };

  // Customizable Area End
}
