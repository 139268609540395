import { Box, Typography, Tabs, InputBase, InputAdornment, IconButton, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Menu, MenuItem, Button, Tab, styled, Grid, FormGroup, FormControlLabel, Radio, Checkbox } from "@material-ui/core";
import { seacrhIcon, sortingIcon, filterIcon } from "../../../brandpages/src/assets";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Pagination, PaginationItem } from "@mui/material";
import { ProductVariation } from "../ListProductVariation.web";
import React, { MouseEvent } from 'react';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';

interface VariationTableProps {
  data: ProductVariation[],
  anchorEl: HTMLElement | null;
  totalResults: number;
  resultperPage: number;
  currentPage: number;
  handleMenuClick: (event: MouseEvent<HTMLElement>) => void;
  handlePageChange: (event: any, page: any) => void,
  handleMenuClose: () => void,
  handleToggle1: () => void,
  open1: boolean,
  handleToggle: () => void,
  open: boolean,
  handleApply: () => void,
  handleClearAll: () => void,
  handleCheckboxChange: (name: string) => void,
  handleRadioChange: (option: string) => void,
  selectedOption: string;
  handleNavigate: (route: string) => void;
  selectedBrands: { [key: string]: boolean };
}

const StyledContainer = styled(Box)({
  marginLeft: "47px",
  "@media(max-width:1258px)": {
    marginLeft: "20px",
  },
  "@media(max-width:600px)": {
    margin: "auto"
  },
  "@media(max-width:400px)": {
    margin: "0px"
  },
  fontFamily: "Inter",
  "& .headers": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "20px 0px",
    fontFamily: "Inter",
  },
  "& .Headingss": {
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "26px",
    textAlign: "left",
    "@media(max-width:600px)": {
      fontSize: "20px",
    },
  },
  "& .CheckoutButton": {
    fontFamily: "Inter",
    textTransform: "none",
    boxShadow: "none",
    backgroundColor: "#ff6600",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "white",
    borderRadius: "13px",
    height: "44px",
    width: "max-content",
    "@media(max-width:600px)": {
      fontSize: "12px",
    },
  },
  "& .tabContainer": {
    flexGrow: 1,
    display: "flex",
    overflowX: "auto",
  },
  "& .searchIcon": {
    width: 20,
    height: 20
  },
  "& .sortingIcon": {
    width: 20,
    height: 20,
    marginLeft: "8px"
  },
  "& .filterIcon": {
    width: 20,
    height: 20
  },
  "& .tab": {
    textTransform: "none",
    height: "58px"
  },
  "& .NavTab": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "58px",
  },
  "& .mainContainer": {
    border: "1px solid #E1E7EF", borderRadius: "8px"
  },
  "& .activeTabs": {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "20px",
    textAlign: "left",
    color: "#EE6900",
  },
  "& .SearchContainers": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "265px",
  },
  "& .SearchInputs": {
    width: "173px",
    height: "35px",
    borderRadius: "9px",
    padding: "0px 10px",
    backgroundColor: "#F3F4F6",
    fontFamily: "Inter",
  },
  "& .TableHeads": {
    backgroundColor: "#f3f4f6",
  },
  "& .TableCellss": {
    fontFamily: "Inter", borderBottom: "none",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    textAlign: "left",
    color: "#565E6C",
    backgroundColor: "#F3F4F6",
    "@media(max-width:600px)": {
      padding: "10px",
      fontSize: "11px",
    }
  },
  "& .Cells1": {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    color: "#323842",
    borderTop: "1px solid #E7E2F0",
    "@media(max-width:600px)": {
      padding: "10px",

    }
  },
  "& .Cells": {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 400,
    color: "#323842",
    borderTop: "1px solid #E7E2F0",
    "@media(max-width:600px)": {
      padding: "10px",
      fontSize: "12px",
    }
  },
  "& .actionCell": {
    borderTop: "1px solid #E7E2Ef",
    borderRight: "1px solid #E7E2EF"
  },
  "& .TBodys": {
    padding: "20px",
  },
  "& .lastTableCell": {
    width: "1%",
    borderLeft: "1px solid #E7E2F0",
    borderTop: "none",
    borderRight: "none",
    borderBottom: "none",
    "@media(max-width:600px)": {
      padding: "0px !important"
    }
  },
  "& .firstCell": {
    width: "0%", borderBottom: "none", borderRight: "1px solid #E7E2F0",
    "@media(max-width:600px)": {
      padding: "0px !important"
    }
  },
  "& .infoCellContainer": {
    display: "flex", alignItems: "center", justifyContent: "space-between"
  },
  "& .infoText": {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 400,
    color: "#64748B",
  },
  "& .paginationItem": {
    textDecoration: "underline",
    borderRadius: 0,
    background: "none",
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 400,
    color: "#979797",
  },
  "& .editIconContainer": {
    fontSize: "14px",
    display: "flex",
    alignItems: "center"
  },
  "& .lastTableCellContent": {
    "@media(max-width:600px)": {
      padding: "10px !important"
    }
  },
  "& .firstCellHead": {
    "@media(max-width:600px)": {
      padding: "0px !important"
    }
  },
  "& .lastCellHead": {
    "@media(max-width:600px)": {
      padding: "0px !important"
    }
  },
  "& .sortByText": {
    fontSize: "1.25rem", fontWeight: "bold", color: "black"
  },
  "& .ModifiedByText": {
    color: "black", fontWeight: "bold", fontSize: "1.15rem", paddingTop: "10px", paddingBottom: "10px"
  },
  "& .filterContainerBox": {
    position: "absolute",
    top: "85%",
    right: "95%",
    width: 450,
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    padding: 0,
    borderRadius: 2,
    zIndex: 5,
    "@media(max-width:600px)": {
      width: 350,
      right: "30%"
    },
    "@media(max-width:400px)": {
      width: 320
    }
  },
  "& .sortByContainer": {
    position: "absolute",
    top: "85%",
    right: "0",
    width: 300,
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: 10,
    padding: 0,
    zIndex: 5,
    "@media(max-width:400px)": {
      right: -20
    }
  },
  "& .sortByContainerContent": {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #DEE1E6",
    marginBottom: 16,
    padding: "15px 20px",
  },
  "& .clearAllButton": {
    backgroundColor: "#FFF6F0",
    color: "#FF6600",
    paddingLeft: "35px",
    paddingRight: "35px",
    borderRadius: "15px"
  },
  "& .actionButtonContainer": {
    display: "flex", justifyContent: "space-between", marginTop: 16, padding: "20px",
    "@media(max-width:400px)": {
      padding: 0
    }
  },
  "& .applyBtnStyled": {
    backgroundColor: "#FF6600", color: "#fff", paddingLeft: "55px", paddingRight: "55px", borderRadius: "15px"
  },
  "& .horizontalLine": {
    border: 0, borderTop: "1px solid #DEE1E6", marginBottom: 16
  },
  "& .filterText": {
    fontSize: "1.25rem", fontWeight: "bold", color: "black"
  },
  "& .filterContainerContent1": {
    display: "flex", marginBottom: 16, paddingLeft: "15px", paddingTop: "30px"
  }
})

const StyledMenuItem = styled(MenuItem)({
  fontSize: "14px",
  padding: "9px 0px 9px 15px",
  minWidth: 105,
  gap: 8,
  display: "flex",
  alignItems: "center",
})

const VariationListTable = (props: VariationTableProps) => {
  const tabData = [
    { label: 'All Results', value: 0 },
  ];
  const brandkeys = Object.keys(props.selectedBrands);
  const half = Math.ceil(brandkeys.length / 2);
  const leftBrands = brandkeys.slice(0, half);
  const rightBrands = brandkeys.slice(half);
  return (
    <StyledContainer>
      <Box className="headers">
        <Typography variant="h6" className="Headingss">
          Variations
        </Typography>
        <Button variant="contained" className="CheckoutButton" onClick={() => props.handleNavigate("ProductVariation")}>
          Add New Variation
        </Button>
      </Box>
      <Box className="mainContainer">
        <Box className="NavTab">
          <Box
            className="tabContainer"
          >
            <Tabs value={0} TabIndicatorProps={{
              style: { background: "#EE6900", height: "4px", borderRadius: "8px" },
            }}>
              {tabData.map(({ label, value }) => (
                <Tab
                  key={value}
                  className="tab"
                  label={
                    <Typography className="activeTabs">
                      {label}
                    </Typography>
                  }
                />
              ))}
            </Tabs>
          </Box>
          <Box className="SearchContainers">
            <InputBase
              placeholder="Search"
              className="SearchInputs"
              inputProps={{
                style: {
                  fontFamily: "Inter",
                  fontSize: 12,
                  fontWeight: 400,
                  color: "rgba(0, 0, 0, 0.87)",
                },
              }}
              startAdornment={
                <InputAdornment position="start">
                  <img src={seacrhIcon} alt="search icon" data-testId="Serach-image" className="searchIcon" />
                </InputAdornment>
              }
            />
            <IconButton>
              <img src={sortingIcon} onClick={props.handleToggle1} className="sortingIcon" alt="sorting icon" data-testId="MoreVertIcon" />
              {props.open1 && (
                <Box
                  className="sortByContainer"
                >
                  <div
                    className="sortByContainerContent"
                  >
                    <img
                      src={sortingIcon}
                      alt="sort icon"
                      className="sortingIcon"
                    />
                    <span className="sortByText">
                      Sort By
                    </span>
                  </div>
                  <Grid container spacing={2} style={{ padding: "0px 50px 20px 30px" }}>
                    <Typography className="ModifiedByText">Modified Date & Time </Typography>
                    <FormGroup>
                      <FormControlLabel style={{ color: "black" }}
                        control={
                          <Radio
                            checked={props.selectedOption === "newest"}
                            onChange={() => props.handleRadioChange("newest")}
                            value="newest"
                            style={{ color: "#FF6600" }}
                            data-testid="newest-radio"
                          />
                        }
                        label="Newest to Oldest"
                      />
                      <FormControlLabel style={{ color: "black" }}
                        control={
                          <Radio
                            checked={props.selectedOption === "oldest"}
                            onChange={() => props.handleRadioChange("oldest")}
                            value="oldest"
                            style={{ color: "#FF6600" }}
                            data-testid="oldest-radio"
                          />
                        }
                        label="Oldest to Newest"
                      />
                    </FormGroup>
                  </Grid>
                </Box>
              )}
            </IconButton>
            <IconButton>
              <img src={filterIcon} onClick={props.handleToggle} alt="filter icon" className="filterIcon" data-testId="filterIcons" style={{ width: 20, height: 20 }} />
              {props.open && (
                <Box
                  className="filterContainerBox"
                >
                  <div className="filterContainerContent1">
                    <img
                      src={filterIcon}
                      alt="filter icon"
                      data-testId="filterIcons"
                      style={{ width: 20, height: 20, marginRight: 8 }}
                    />
                    <span className="filterText">FILTER</span>
                  </div>
                  <hr className="horizontalLine" />
                  <Grid container spacing={2}>
                    <Grid item xs={6} style={{ paddingLeft: "30px" }}>
                      <FormGroup>
                        {leftBrands.map((brand) => (
                          <FormControlLabel style={{ color: "black" }}
                            key={brand}
                            control={
                              <Checkbox
                                checked={props.selectedBrands[brand]}
                                onChange={() => props.handleCheckboxChange(brand)}
                                style={{
                                  color: "#FF6600",
                                }}
                              />
                            }
                            label={brand}
                          />
                        ))}
                      </FormGroup>
                    </Grid>
                    <Grid item xs={6} style={{ paddingRight: "50px", paddingLeft: "15px" }}>
                      <FormGroup>
                        {rightBrands.map((brand) => (
                          <FormControlLabel style={{ color: "black" }}
                            key={brand}
                            control={
                              <Checkbox
                                checked={props.selectedBrands[brand]}
                                onChange={() => props.handleCheckboxChange(brand)}
                                style={{
                                  color: "#FF6600",
                                }}
                              />
                            }
                            label={brand}
                          />
                        ))}
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <hr className="horizontalLine" />
                  <div className="actionButtonContainer">
                    <Button onClick={props.handleClearAll} className="clearAllButton">
                      Clear All
                    </Button>
                    <Button
                      onClick={props.handleApply}
                      variant="contained"
                      className="applyBtnStyled"
                    >
                      Apply
                    </Button>
                  </div>
                </Box>
              )}
            </IconButton>
          </Box>
        </Box>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead className="TableHeads">
              <TableRow style={{ borderBottom: "none" }}>
                <TableCell style={{ width: "0%", borderBottom: "none" }} className="firstCellHead"></TableCell>
                <TableCell align="left" className="TableCellss">
                  UPID Name
                </TableCell>
                <TableCell align="left" className="TableCellss">
                  Criteria 01
                </TableCell>
                <TableCell align="left" className="TableCellss">
                  Criteria 02
                </TableCell>
                <TableCell align="left" className="TableCellss">
                  Criteria 03
                </TableCell>
                <TableCell align="left" className="TableCellss">
                  Actions
                </TableCell>
                <TableCell style={{ width: "0%", borderBottom: "none" }} className="lastCellHead"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="TBodys">
              <TableRow style={{ height: "30px" }}></TableRow>
              {props.data.map((brand, index) => (
                <TableRow key={index} data-testId="Rowclick" style={{ height: "45px" }}>
                  <TableCell className="firstCell"></TableCell>
                  <TableCell className="Cells1">{brand.upiName}</TableCell>
                  <TableCell className="Cells">{brand.criteria01}</TableCell>
                  <TableCell className="Cells">{brand.criteria02}</TableCell>
                  <TableCell className="Cells">{brand.criteria03}</TableCell>
                  <TableCell align="left" className="actionCell">
                    <IconButton
                      aria-label="more"
                      data-testId="MoreVertIcon"
                      onClick={props.handleMenuClick}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={props.anchorEl}
                      keepMounted
                      data-testId="MoreVertIcon"
                      open={Boolean(props.anchorEl)}
                      onClose={props.handleMenuClose}
                      PaperProps={{
                        style: { boxShadow: 'none', border: "1px solid #E7E2Ef" },
                      }}
                    >
                      <StyledMenuItem data-testId="EditMenuItem" className="editIconContainer"> <CreateIcon style={{ height: 18, width: 18 }} /><span style={{ marginTop: 4 }}>Edit</span></StyledMenuItem>
                      <StyledMenuItem data-testId="DeleteMenuItem" className="editIconContainer"><DeleteIcon style={{ height: 18, width: 18 }} /><span style={{ marginTop: 4 }}>Delete</span></StyledMenuItem>
                    </Menu>
                  </TableCell>
                  <TableCell style={{ width: "0%", borderBottom: "none" }} className="lastCellHead"></TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell className="lastTableCell"></TableCell>
                <TableCell style={{ borderLeft: "1px solid #E7E2F0" }} className="lastTableCellContent" colSpan={5}>
                  <div className="infoCellContainer">
                    <span
                      className="infoText"
                    >
                      Showing {props.resultperPage * (props.currentPage - 1) + 1} -{" "}
                      {Math.min(
                        props.resultperPage * props.currentPage,
                        props.totalResults
                      )}{" "}
                      of {props.totalResults} results
                    </span>
                    <Pagination
                      count={Math.ceil(props.totalResults / props.resultperPage)}
                      page={props.currentPage}
                      onChange={props.handlePageChange}
                      siblingCount={0}
                      boundaryCount={1}
                      renderItem={(item: any) => (
                        <PaginationItem
                          {...item}
                          className="paginationItem"
                        />
                      )}
                    />
                  </div>
                </TableCell>
                <TableCell className="lastTableCell"></TableCell>
              </TableRow>
              <TableRow style={{ height: "30px" }}></TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </StyledContainer>
  );
}

export default VariationListTable;