import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {  MouseEvent } from 'react';
import {Message} from "../../../framework/src/Message"
import { getStorageData } from "../../../framework/src/Utilities";
import { Alert } from "react-native";

// Customizable Area Start
interface BrandAttributes {
  id: number;
  name: string;
  primary_contact_name:null;
  primary_contact_number:number;
  created_at: string;
  updated_at: string;
  industry: string;
  images: {
    url: string;
  };
  no_of_catalogues: number;
  no_of_prducts: number;
  brand_certificates: any[];
}

interface Brand {
  id: string;
  type: string;
  attributes: BrandAttributes;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  history: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  sidebarToggle: boolean;
  openDialog: boolean;
  activeComponent: string;
  anchorEl: HTMLElement | null;
  value: number | 0;
  totalResults: number;
  resultperPage : number;
  currentPage: number;
  brandNamesList:{name:string,industry:string,primary_contact_name:null,primary_contact_number:number}[];
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
/*istanbul ignore next*/
export default class BrandsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getBrandsApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
   // Customizable Area Start
   sidebarToggle: false,
   openDialog: true,
   activeComponent: "Company",
   anchorEl: null,
   value: 12,
   totalResults: 140, 
   resultperPage: 12, 
   currentPage: 1 ,
   brandNamesList:[],
  // Customizable Area End
  
 // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start  
    // Customizable Area End
  }

  async componentDidMount() {
     // Customizable Area Start  
    await super.componentDidMount();
     
    this.getBrandsAPICall(configJSON.httpGetMethod,this.state.currentPage );

// Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    console.log("test");
    

if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
  const apiRequestCallId = message.getData(
    getName(MessageEnum.RestAPIResponceDataMessage)
  );
  const responseJson = message.getData(
    getName(MessageEnum.RestAPIResponceSuccessMessage)
  );
  
  if (apiRequestCallId === this.getBrandsApiCallId) {
    if (!responseJson.errors) {
      console.log("response JSON",responseJson);
      const { count, items, page } = responseJson.meta?.pagy || {}; 
        const brandData: {name: string,industry:string,primary_contact_name:null,primary_contact_number:number }[] = responseJson.data.map((item: Brand) => ({
          name: item.attributes.name,
          industry: item.attributes.industry,
          primary_contact_name:item.attributes.primary_contact_name,
          primary_contact_number:item.attributes.primary_contact_number
        }))
        console.log("Brand Data:", brandData); 
        this.setState({ brandNamesList: brandData, 
          totalResults: count | 0,
          currentPage:page|1,
          resultperPage:items|12

        })
    } else {
      Alert.alert(responseJson.errors[0].token);
    }
  }
}
  }

  getBrandsAPICall = async (method: string ,page: any) => {
    const header = {
      // "Content-Type": configJSON.ContentType,
      token: await getStorageData("authToken"),
    };

const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);

this.getBrandsApiCallId = requestMessage.messageId;
const queryParams = `?page=${page}&size=${this.state.resultperPage}`;
  const endPoint = `${configJSON.getBrandsEndPoint}${queryParams}`;


requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  endPoint
);
requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  method
);

runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  navigateToBrandCatalogue = () => {
    const brandCatalogueRoute = '/Addbrands'; 
    window.location.href = brandCatalogueRoute; 
  };
  handlePageChange = (event:any , page:any) => {
    this.setState({ currentPage: page }, () => {
      this.getBrandsAPICall(configJSON.httpGetMethod, page);
    });
  };
  

  getResultText = (page: number): string => {
    const { resultperPage, totalResults } = this.state;
    const start = (page - 1) * resultperPage + 1;
    const end = Math.min(start + resultperPage - 1, totalResults);
    return `${start}-${end} of ${totalResults} results`;
  }

  handleMenuClick = (event: MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = (): void => {
    this.setState({ anchorEl: null });
  };
  // Customizable Area End
}
