import React from "react";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { Box, Grid } from "@material-ui/core";
import ProductCatalogueController, { ProductCat, Props } from "./ProductCatalogueController";
import { Typography } from "@mui/material";
import Navbar from "../../../components/src/Navbar.web";
import { back } from "./assets";
import SubCategoryCarousel from "../../../../packages/components/src/Carousel";
import  Filter from "../../../../packages/components/src/FilterCatalogue";
import { SortByDropDown } from "../../../../packages/components/src/SortByDropDown";
import { ChipList } from "../../../../packages/components/src/ChipsList";
import { ProductCard } from "../../../../packages/components/src/ProductCard";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
});

// Customizable Area End

export default class ProductCatalogue extends ProductCatalogueController {
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  conditions = [{ name: 'Condition 1', active: true }, { name: 'Condition 2', active: false }]
  filter = () => {
    return (
      <Filter handleToggleSidebar={this.handleToggleSidebar}
        catBlock={this.state.catBlock}
        data-test-id="filterCarousel" />
    )
  }

  listOfProduct = () => {
    return (<Box className="listOfProductContainer">
      <Grid container direction="row" spacing={1} style={{ width: "95% !important" }} className="listGrid">
        <Grid item className="gridBox">
          <Grid container>
            <Grid item style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
              <Grid item>
                <Box className="main-heading">
                  <Typography className="numberItems">
                    Showing 10 of 179 items
                  </Typography>
                </Box>
              </Grid>
              <SortByDropDown
                handleOpen={this.handleOpen}
                handleDropdownClick={this.handleDropdownClick}
                showFilterData={this.state.showFilterData}
                chipData={this.state.chipData} 
                data-test-id="sortByDrop"/>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
              <Box style={{ position: 'relative' }}>
                <ChipList shortChipData={this.state.shortChipData} handlePropsDelete={this.handleDelete} delete={this.delete} data-test-id="chipsList"/>
                <Grid container spacing={4} xl={12} lg={12} md={12} sm={12} style={{ marginTop: "0px", marginRight: "0px" }}>
                  {this.state.productList.map((product: ProductCat) => (
                    <Grid item xl={3} lg={3} md={6} sm={6} xs={12} >
                      <ProductCard product={product} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>)
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Navbar {...this.props} history={this.props.history} handleNavigate={this.handleNavigate} data-test-id="navbar" />
        <StyledCatalogueContainer>
          <Grid container className="productCatalogueTitleContainer">
            <Grid item sm={12} xs={12} md={5} lg={4} xl={4} className="titleContainer">
              <Typography className="mintitle">Plumbing</Typography>
              <Typography className="title">Plumbing</Typography>
              <Typography className="description"> Plumbing is any system that conveys fluids for a wide range
                of applications.
              </Typography>

            </Grid>
            <Grid item sm={12} xs={12} md={7} lg={8} xl={8} style={{ padding: "10px 0px 10px 0px" }}>
              <SubCategoryCarousel images={[back, back, back, back, back, back, back, back, back, back, back]} />
            </Grid>
          </Grid>
          <Grid container className="filterDataContainer">
            <Grid item xl={3} lg={3} md={4}>
              {this.filter()}
            </Grid>
            <Grid item xl={9} lg={9} md={8} sm={12}>
              {this.listOfProduct()}
            </Grid>
          </Grid>
        </StyledCatalogueContainer>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start

const StyledCatalogueContainer = styled(Box)({
  "& .productCatalogueTitleContainer": {
    borderBottom: "1px solid #E2E8F0",
    height: "170px",
    display: "flex",
    width: "99%",
    "@media(max-width:960px)": {
      height: "auto",
    }
  },
  "& .titleContainer": {
    borderRight: "1px solid #BCC1CA",
    paddingLeft: "50px",
    marginTop: "30px",
    paddingRight: "40px",
    marginBottom:"10px"
  },
  "& .mintitle": {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    color: " #171A1F"
  },
  "& .title": {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "26px",
    color: " #171A1F",
    marginTop: "12px"
  },
  "& .description": {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    color: " #9095A0",
    marginTop: "12px",
    "@media(max-width:1440)": {
      maxWidth: "400px",
    }
  },
  "& .listOfProductContainer": {
    marginTop: "30px",
    "& .listGrid": {
      "@media (max-width:960px)": {
        margin: "auto",
        width: "80%"
      },
      "@media (max-width: 600px)": {
        width: "80% !important",
        justifyContent: "center",
        display: "flex",
        margin: "auto"
      },
    },
    "@media(max-width:905px)": {
      marginTop: "0px",
    }
  },
  "& .filterDataContainer": {
    display: "flex",
    width: "99%",
    "@media(max-width:905px)": {
      width: "99%",
    }
  },

  "& .img-title": {
    fontSize: "38px", fontWeight: 700, color: "white", position: "absolute", left: "80px", zIndex: 1, top: "68px",
    "@media (max-width:650px)": {
      fontSize: "18px",
      left: "20px"
    },
    "@media (max-width:900px)": {
      left: "20px"
    },
  },
  "& .img-slider": {
    width: "100%",
    height: "200px",
    position: "relative",
    "@media (max-width:600px)": {
      height: "150px",
    },
  },
  "& .MuiChip-root": {
    backgroundColor: "#E2E8F0"
  },
  "& .banner-txt": {
    marginTop: "10px",
  },
  "& .pagination-content": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "30px"
  },
  "& .next-btn": {
    width: "63px",
    height: "29px",
    backgroundColor: "#FF6600",
    color: "white"
  },

  "& .cat-box:hover .data-box": {
    display: "flex", visibility: "visible"
  },
  "& .data-box": {
    position: "absolute",
    top: 0,
    right: 0,
    display: "none",
    flexDirection: "column",
    gap: "15px",
    visibility: "hidden",
    backgroundColor: "#fff",
    padding: "10px",
    zIndex: 1,
    background: "none !important"
  },
  "& .MuiPaginationItem-page.Mui-selected": {
    border: "none",
    backgroundColor: "white",
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "12px",
    color: "#979797",
  },
  "& .MuiPaginationItem-ellipsis": {
    borderBottom: "1px solid #FFF !important"
  },
  '[aria-label="Go to next page"]': {
    borderBottom: "1px solid #FFF !important"
  },
  "& .MuiPaginationItem-root": {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "12px",
    color: "#979797",
    borderRadius: "0px",
    borderBottom: "1px solid #979797"
  },
  "& .filter-content": {
    display: "flex", justifyContent: 'right', gap: "12px", marginRight: "0px",
    "@media (min-width:1240px)": {
      display: "flex",
      alignItems: "center",
      marginRight: "120px",
    },
  },
  "& .navigation-text": {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    color: "#171A1F",
  },
  "& .navigation-text-main": {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "24px",
    color: "#171A1F",
    marginTop: "12px"
  },
  "& .navigation-text-sec": {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "14px",
    color: "#9095A0",
    marginTop: "12px"
  },
  "& .navigation-categories": {
    padding: "30px 50px",
    borderBottom: "1px solid #E2E8F0"
  },
  "& .nav-separator": {
    height: "100%",
    border: "1px solid #BCC1CA",
    margin: "0px 40px"
  },
  "& .third-level-categories-img-active": {
    width: "90px",
    height: "90px",
    borderRadius: "12px",
    border: "1px solid #FF6600",
    borderShadow: "none",
  },
  "& .third-level-categories-img": {
    width: "90px",
    height: "90px",
    borderRadius: "12px",
  },
  "& .third-level-categories-text-active": {
    textAlign: "center",
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "14px",
    color: "#171A1F",
    marginTop: "4px"
  },
  "& .third-level-categories-text": {
    textAlign: "center",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    color: "#171A1F",
    marginTop: "4px",
  },
  "& .third-level-categories": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginRight: "30px",
  },
  "& .numberItems": {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    color: "#9095A0",
  },
  "& .sortingText": {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    color: "#171A1F",
  },
  "& .cat-box-img-container": {
    padding: "20px 0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  "& .cat-box-img": {
    height: "94px",
    width: "148px",
    border: "1px solid",
    borderRadius: "5px"
  },
  "& .cat-box-name": {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    color: "#171A1F",
    textAlign: "center",
  },
  "& .productDescription": {
    padding: "10px 20px"
  },
  "& .cat-box-size": {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "12px",
    color: "#9095A0",
    marginTop: "12px",
    marginBottom: "6px"
  },
  "& .cat-box-type": {
    border: "1px solid #9095A0",
    borderRadius: "13px",
    textAlign: "center",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "12px",
    color: "#9095A0",
    display: "flex",
    padding: "6px 12px",
    marginTop: "12px",
    lineHeight: "13px",
    marginLeft: "5px"
  },
  "& .cat-box-type-container": {
    display: "flex"
  },
  "& .pagination": {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    color: "#979797",
    marginTop: "20px"
  },
  "& .paginationItems": {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: "4px",
    margin: "0px 10px"
  },
  "& .paginationItems__page-active": {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "12px",
    color: "#979797",
    padding: "3px 5px",
    lineHeight: "18px"
  },
  "& .paginationItems__page": {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "12px",
    color: "#979797",
    padding: "3px 5px",
    lineHeight: "18px",
    textAlign: "center",
  },
  "& .pagination__number": {
    borderBottom: "1px solid #979797"
  },

  "& .cat-box": {
    border: "1px solid #CBD5E1",
    borderRadius: "4px",
    position: "relative",
    height: "270px"
  },
  "& .cat-img": {
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    mixBlendMode: "multiply"
  },
  "& .cat-desc": {
    fontSize: "15px", fontWeight: 600, margin: "10px",
    overflowY: "auto",
    maxHeight: "95px"
  },
  "& .price-txt": {
    fontSize: "16px",
    fontWeight: 700,
    color: "#000000"
  },
  "& .cut-price": {
    fontSize: "16px",
    fontWeight: 400,
    color: "#000000"
  },
  "& .offer-txt": {
    fontSize: "14px",
    fontWeight: 500,
    color: "#FF6600"
  },

})
// Customizable Area End
