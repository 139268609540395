import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Box, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';

interface SubCategoryCarouselProps {
  images: string[];
}

const SubCategoryCarousel: React.FC<SubCategoryCarouselProps> = ({ images }) => {
  const [itemsPerSlide, setItemsPerSlide] = useState<number>(8);
  useEffect(() => {
    const updateItemsPerSlide = () => {
      const width = window.innerWidth;
      if (width <= 478) {
        setItemsPerSlide(2);
      }
      else if (width <= 518) {
        setItemsPerSlide(3);
      }
      else if (width <= 590) {
        setItemsPerSlide(4);
      } else if (width <= 960) {
        setItemsPerSlide(5);
      } else if (width <= 1200) {
        setItemsPerSlide(4);
      } else if (width <= 1400) {
        setItemsPerSlide(6);
      } else {
        setItemsPerSlide(8);
      }
    };
    updateItemsPerSlide();

    window.addEventListener('resize', updateItemsPerSlide);
    return () => {
      window.removeEventListener('resize', updateItemsPerSlide);
    };
  }, []);
  const chunkImages = (arr: string[], size: number) => {
    const result: string[][] = [];
    for (let i = 0; i < arr.length; i += size) {
      result.push(arr.slice(i, i + size));
    }
    return result;
  };

  const imageChunks = chunkImages(images, itemsPerSlide);

  return (
    <CarouselContainer>
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        dynamicHeight={false}
        useKeyboardArrows={true}
        autoPlay={false}
        interval={3000}
        transitionTime={500}
        swipeable={true}
        emulateTouch={true}
        stopOnHover={false}
        className="custom-carousel"
        renderArrowPrev={(clickHandler) => (
          <CustomArrow onClick={clickHandler}>
            <ArrowBackIos />
          </CustomArrow>
        )}
        renderArrowNext={(clickHandler) => (
          <CustomArrow onClick={clickHandler}>
            <ArrowForwardIos />
          </CustomArrow>
        )}
      >
        {imageChunks.map((chunk, index) => (
          <CarouselGrid key={index} style={{}} className="carouselGrid">
            {chunk.map((image, idx) => (
              <CarouselItem key={idx}>
                <Box className="slide" style={{ height: '85px', width: '85px' }}>
                  <img src={image} style={{ height: '78px', width: '78px' }} alt={`Slide ${index}-${idx}`} className="third-level-categories-img-active" />
                </Box>
                <Typography className="third-level-categories-text-active">Pipes</Typography>
              </CarouselItem>
            ))}
          </CarouselGrid>
        ))}
      </Carousel>
    </CarouselContainer>
  );
};
const CarouselContainer = styled('div')({
  width: '100%',
  margin: '0 auto',
  padding: '20px 0',
  "& .carousel-status": {
    display: "none"
  },
  "& .control-dots": {
    display: "none"
  },
  "& .carouselGrid": {
    width: '95%', margin: 'auto',
    "@media (max-width:1500px)": {
      width: '89%',
    },
  },
  "& .third-level-categories-text-active": {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    color: "#171A1F",
    textAlign: "center",
  },
  
});

const CarouselGrid = styled('div')({
  display: 'flex',
  justifyContent: "center",
  gap: "25px",

  "@media (max-width:1440px)": {
    gap: "26px",
  },

});

const CarouselItem = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
});

const CustomArrow = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'left',
})<{ left?: boolean }>(({ left }) => ({
  color: 'black',
  padding: '10px',
  borderRadius: '50%',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    color: "white"
  },
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 2,
  '&:first-of-type': {
    left: '10px',
  },
  '&:last-of-type': {
    right: '10px',
    "@media (max-width:1440px)": {
      right: '6px',
    },
  },
}));

export default SubCategoryCarousel;
