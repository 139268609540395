import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");



export interface Props {
  // Customizable Area Start
  children?: React.ReactNode;
  navigation: any;
  id: string;
  history: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  sidebarToggle: boolean;
  showVariations1:boolean;
  notshow:boolean;
  criteria: string;
  values: string;
  fields:any;
  showVariations:boolean;
  showEdit:boolean;
  showVariations2:boolean;
  yourChips:string[];

  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProductVariationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),      
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];


    this.state = {
      // Customizable Area Start
      showVariations2:false,
      showEdit:false,
      sidebarToggle: false,
      notshow:false,
      criteria: "",
      values: "",
      fields: [{ productCriteria: "", value: "" }],
      showVariations: false,
      showVariations1:false,
      yourChips:[]
      // Customizable Area End

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as unknown as IBlock, this.subScribedMessages);   
     // Customizable Area Start  
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start  
    await super.componentDidMount?.();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
  
  }

  handleNavigate = (route?: string) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)

  }

  addField = () => {
    if (this.state.fields.length < 3) {
      this.setState((prevState) => ({
        fields: [
          ...prevState.fields,
          { productCriteria: "", value: "" },
        ],
      }));
    }
  };

 
  
  removeField = (index:number) => {
    const newFields = this.state.fields.filter((_:any, i:any) => i !== index);
    this.setState({ fields: newFields });
  };

 
handleFieldChange = (index: number, value: any) => {
  const newFields = [...this.state.fields];
  newFields[index].value = value; 
  this.setState({ fields: newFields });
};


handleCriteriaChange = (index: number, event: any) => {
  const newFields = [...this.state.fields];
  newFields[index].productCriteria = event.target.value; 
  this.setState({ fields: newFields });
};
handleGenerateVariations = () => {
  this.setState({ showVariations: true ,showVariations1: true,notshow:true });
};
handleSave = () => {
  this.setState({ showEdit: true ,notshow:false});
};

handleAddChip = (chip: string) => {
  this.setState((prevState) => ({
    yourChips: [...prevState.yourChips, chip],
  }));
};


handleDeleteChip = (index: number) => {
  this.setState((prevState) => ({
    yourChips: prevState.yourChips.filter((_, i) => i !== index),
  }));
};

  // Customizable Area End
}
