import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {Message} from "../../../framework/src/Message"
import { getStorageData } from "../../../framework/src/Utilities";
import { Alert } from "react-native";

// Customizable Area Start
interface BrandAttributes {
  id: number;
  name: string;
  parent_category_id: number | null;
  level: number;
  is_upid: boolean;
  created_at: string;
  updated_at: string;
  image: {
    url: string;
  };
}

interface Brand1 {
  id: string;
  type: string;
  attributes: BrandAttributes;
}

interface Brand {
  data: {
    data: Brand1[];  // Array of categories
  };
}

interface SubCategoryAttributes {
  id: number;
  name: string;
  parent_category_id: number;
  level: number;
  is_upid: boolean;
  created_at: string;
  updated_at: string;
  image: string | null;
}

interface SubCategoryData {
  id: string;
  type: string;
  attributes: SubCategoryAttributes;
}

interface SubCategoryResponse {
  sub_categories: {
    data: SubCategoryData[];
  };
}

interface ProductAttributes {
  id: number;
  name: string;
}

interface ProductData {
  id: string;
  type: string;
  attributes: ProductAttributes;
}

interface ProductResponse {
  data: ProductData[];
  meta: {
    pagy: PagyMeta;
  };
}

interface PhysicalFormAttributes {
  id: number;
  name: string;
}

interface PhysicalFormData {
  id: string;
  type: string;
  attributes: PhysicalFormAttributes;
}

interface PhysicalFormResponse {
  data: PhysicalFormData[];
  meta: {
    pagy: PagyMeta;
  };
}

interface IndustryMarketAttributes {
  name: string;
}

interface IndustryMarketData {
  id: string;
  type: string;
  attributes: IndustryMarketAttributes;
}
interface IndustryMarketResponse {
  data: IndustryMarketData[];
  meta: {
    pagy: PagyMeta;
  };
}

interface ImageData {
  url: string;
}

interface ApplicationGroupAttributes {
  application_group_name: string;
  image: ImageData;
}

interface ApplicationGroupData {
  id: string;
  type: string;
  attributes: ApplicationGroupAttributes;
}

interface ApplicationGroupResponse {
  data: ApplicationGroupData[];
  meta: {
    pagy: PagyMeta;
  };
}

interface ProductApplicationAttributes {
  application_name: string;
}

interface ProductApplicationData {
  id: string;
  type: string;
  attributes: ProductApplicationAttributes;
}

interface LogisticalCodeResponse {
  data: LogisticalCode[];
  meta: {
    pagy: PagyMeta;
  };
}

interface LogisticalCode {
  id: string;
  type: string;
  attributes: LogisticalCodeAttributes;
}

interface LogisticalCodeAttributes {
  id: number;
  product_tag: string;
  logisticl_codes: string;
}


interface PagyVars {
  page: number;
  items: number;
  outset: number;
  size: number[];
  page_param: string;
  params: Record<string, unknown>;
  fragment: string;
  link_extra: string;
  i18n_key: string;
  cycle: boolean;
  request_path: string;
  count: number;
}

interface PagyMeta {
  vars: PagyVars;
  count: number;
  page: number;
  outset: number;
  items: number;
  last: number;
  pages: number;
  offset: number;
  params: Record<string, unknown>;
  from: number;
  to: number;
  in: number;
  prev: number | null;
  next: number | null;
}

interface ProductApplicationResponse {
  data: ProductApplicationData[];
  meta: {
    pagy: PagyMeta;
  };
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  history: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  sidebarToggle: boolean;
  openDialog: boolean;
  activeComponent: string;
  anchorEl: HTMLElement | null;
  value: number | 0;
  value1: number | 0;
  totalResults: number;
  resultperPage : number;
  currentPage: number;
  supportContact:string;
  upid: string;
  productVersionName: string;
  alias: string;
  quantity: string;
  productSubcategory: string;
  productDescription: string;
  operators:string;
  quantity1:string;
  hsncode:string;
  heading:string;
  subheading:string;
  productDescription1:string;
  cgst:string;
  sgst:string;
  igst:string;
  materialcomp:string;
  feature:string;
  key:string;
  unit:string;
  brandNamesList:{id:number,name:string}[];
  brandNamesList1:{id:number,name:string}[];
  brandNamesList2:{id:number,name:string}[];
  brandNamesList3:{id:number,name:string}[];
  brandNamesList4:{id:number,name:string}[];
  brandNamesList5:{id:number,name:string}[];
  brandNamesList6:{id:number,name:string}[];
  brandNamesList7:{id:number,name:string}[];

  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddUPIDController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
 addUPIDCall:string|Message=''
 getBrandsApiCallId: string = "";
 getsubproductApiCallId:string = "";
 getproducttypeApiCallId:string = "";
 getphysicalformApiCallId:string = "";
 getindustrymarketApiCallId:string = "";
 getapplicationgroupApiCallId:string = "";
 getapplicationApiCallId:string = "";
 getlogisticalApiCallId:string = "";
 
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
   // Customizable Area Start
   sidebarToggle: false,
   openDialog: true,
   activeComponent: "Company",
   anchorEl: null,
   value: 0,
   value1:0,
   totalResults: 140, 
   resultperPage: 12, 
   currentPage: 1 ,
   supportContact:"",
   upid: "",
   productVersionName: "",
   alias: "",
   quantity:"",
   quantity1:"",
   productSubcategory: "",
   productDescription: "",
   productDescription1: "",
   operators:'',
   hsncode:"",
   heading:"",
   subheading:"",
   cgst:"",
   sgst:"",
   igst:"",
   materialcomp:"",
   feature:"",
   key:"",
   unit:"",
   brandNamesList:[],
   brandNamesList1:[],
   brandNamesList2:[],
   brandNamesList3:[],
   brandNamesList4:[],
   brandNamesList5:[],
   brandNamesList6:[],
   brandNamesList7:[],
  // Customizable Area End
  
 // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start  
    // Customizable Area End
  }

  async componentDidMount() {
     // Customizable Area Start  
    await super.componentDidMount(); 
    this.getBrandsAPICall("GET");
    this.getproducttypeAPICall("GET");
    this.getphysicalformAPICall("GET");
    this.getindustrymarketAPICall("GET");
    this.getapplicationgroupAPICall("GET");
    this.getapplicationAPICall("GET");
    this.getlogisticalAPICall("GET");
// Customizable Area End
  }

  // Customizable Area Start
  /*istanbul ignore next*/
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    console.log("test");
    

if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
  const apiRequestCallId = message.getData(
    getName(MessageEnum.RestAPIResponceDataMessage)
  );
  const responseJson = message.getData(
    getName(MessageEnum.RestAPIResponceSuccessMessage)
  );
  
  if (apiRequestCallId === this.getBrandsApiCallId) {
    if (!responseJson.errors) {
      console.log("response JSON",responseJson.data);
        const brandData: {id:number,name: string}[] = responseJson.data.data.map((item: Brand1) => ({
          id:item.attributes.id,
          name: item.attributes.name,
        }))
        console.log("Brand Data:", brandData); 
        this.setState({ brandNamesList: brandData})
    } else {
      Alert.alert(responseJson.errors[0].token);
    }
  }

  if (apiRequestCallId === this.getsubproductApiCallId) {
    if (!responseJson.errors) {
      console.log("response JSONs", responseJson.data);
      const subProductData: { id: number, name: string }[] = responseJson.sub_categories.data.map((item: SubCategoryData) => ({
        id: item.attributes.id,
        name: item.attributes.name,
      }));
      console.log("SubProduct Data:", subProductData);
      this.setState({ brandNamesList1: subProductData });
    } else {
      Alert.alert(responseJson.errors[0].token);
    }
  }

  if (apiRequestCallId === this.getproducttypeApiCallId) {
    if (!responseJson.errors) {
      console.log("response JSONss", responseJson.data);
      const producttypeData: { id: number, name: string }[] = responseJson.data.map((item: ProductData) => ({
        id: item.attributes.id,
        name: item.attributes.name,
      }));
      console.log("producttype Data:", producttypeData);
      this.setState({ brandNamesList2: producttypeData });
    } else {
      Alert.alert(responseJson.errors[0].token);
    }
  }

  if (apiRequestCallId === this.getphysicalformApiCallId) {
    if (!responseJson.errors) {
      console.log("response JSONs1", responseJson.data);
      const physicalformData: { id: number, name: string }[] = responseJson.data.map((item: PhysicalFormData) => ({
        id: item.attributes.id,
        name: item.attributes.name,
      }));
      console.log("producttype Data:", physicalformData);
      this.setState({ brandNamesList3: physicalformData });
    } else {
      Alert.alert(responseJson.errors[0].token);
    }
  }

  if (apiRequestCallId === this.getindustrymarketApiCallId) {
    if (!responseJson.errors) {
      console.log("response JSON2", responseJson.data);
      const industrymarketData: {id:number,name: string }[] = responseJson.data.map((item: IndustryMarketData) => ({
        name: item.attributes.name,
        id: item.id
      }));
      console.log("producttype Data:", industrymarketData);
      this.setState({ brandNamesList4: industrymarketData });
    } else {
      Alert.alert(responseJson.errors[0].token);
    }
  }

  if (apiRequestCallId === this.getapplicationgroupApiCallId) {
    if (!responseJson.errors) {
      console.log("response JSON3", responseJson.data);
      const applicationgroupData: {id:number,name: string }[] = responseJson.data.map((item: ApplicationGroupData) => ({
        name: item.attributes.application_group_name,
        id: item.id
      }));
      console.log("producttype Data:", applicationgroupData);
      this.setState({ brandNamesList5: applicationgroupData });
    } else {
      Alert.alert(responseJson.errors[0].token);
    }
  }

  if (apiRequestCallId === this.getapplicationApiCallId) {
    if (!responseJson.errors) {
      console.log("response JSON4", responseJson.data);
      const applicationData: {id:number,name: string }[] = responseJson.data.map((item: ProductApplicationData) => ({
        name: item.attributes.application_name,
        id:item.id
      }));
      console.log("producttype Data:", applicationData);
      this.setState({ brandNamesList6: applicationData });
    } else {
      Alert.alert(responseJson.errors[0].token);
    }
  }

  if (apiRequestCallId === this.getlogisticalApiCallId) {
     this.handelLogisticalData(responseJson)
  }
}
  }

  handelLogisticalData(responseJson:any){
  if (!responseJson.errors){
    const logisticalData: { id: number, name: string }[] = responseJson.data.map((item: LogisticalCode) => ({
      id: item.attributes.id,
      name: item.attributes.product_tag,
    }));
    this.setState({ brandNamesList7: logisticalData });
  } else {
    Alert.alert(responseJson.errors[0].token);
  }
}


handleCategoryChange = (category:any) => {
    this.getsubProductAPICall(category.id)
};


getBrandsAPICall = async (method: string) => {
    const header = {
      token: await getStorageData("authToken"),
    };

const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);

this.getBrandsApiCallId = requestMessage.messageId;

requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  configJSON.getBrandsEndPoint
);
requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  method
);

runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getsubProductAPICall = async (Categoryid:any) => {
    const header = {
      token: await getStorageData("authToken"),
    };

const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);

this.getsubproductApiCallId = requestMessage.messageId;

requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  `bx_block_categories/get_sub_categories?parent_category_id=${Categoryid}`
);
requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  configJSON.httpGetMethod
);

runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getproducttypeAPICall = async (method: string) => {
    const header = {
      token: await getStorageData("authToken"),
    };

const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);

this.getproducttypeApiCallId = requestMessage.messageId;

requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  configJSON.getproducttypeEndPoint
);
requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  method
);

runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getphysicalformAPICall = async (method: string) => {
    const header = {
      token: await getStorageData("authToken"),
    };

const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);

this.getphysicalformApiCallId = requestMessage.messageId;

requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  configJSON.getphysicalformEndPoint
);
requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  method
);

runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getindustrymarketAPICall = async (method: string) => {
    const header = {
      token: await getStorageData("authToken"),
    };

const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);

this.getindustrymarketApiCallId = requestMessage.messageId;

requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  configJSON.getindustrymarketEndPoint
);
requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  method
);

runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getapplicationgroupAPICall = async (method: string) => {
    const header = {
      token: await getStorageData("authToken"),
    };

const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);

this.getapplicationgroupApiCallId = requestMessage.messageId;

requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  configJSON.getapplicationgroupEndPoint
);
requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  method
);

runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getapplicationAPICall = async (method: string) => {
    const header = {
      token: await getStorageData("authToken"),
    };

const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);

this.getapplicationApiCallId = requestMessage.messageId;

requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  configJSON.getapplicationEndPoint
);
requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  method
);

runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getlogisticalAPICall = async (method: string) => {
    const header = {
      token: await getStorageData("authToken"),
    };

const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);

this.getlogisticalApiCallId = requestMessage.messageId;

requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  configJSON.getlogisticalEndPoint
);
requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  method
);

runEngine.sendMessage(requestMessage.id, requestMessage);
  };
/*istanbul ignore next*/
  postAddUPIDApi=async()=>{
    const token = await getStorageData('authToken');
    
    const header = {
      token:token,
    };
    
    const formData = new FormData();
    formData.append("upid[product_identifier_id]",this.state.upid)
    formData.append("upid[product_version_name]", this.state.productVersionName)
    formData.append("upid[sample_availability]",this.state.supportContact);
    formData.append("upid[material_composition][]",this.state.materialcomp)
    formData.append("upid[alias][]",this.state.alias)
    formData.append("upid[product_category_id]",this.state.brandNamesList[0].id.toString())
    formData.append("upid[product_sub_category_id]",this.state.brandNamesList1[0].id.toString())
    formData.append("upid[short_description]",this.state.productDescription)
    formData.append("upid[product_type_id]",this.state.brandNamesList2[0].id.toString())
    formData.append("upid[physical_form_id]",this.state.brandNamesList3[0].id.toString())
    formData.append("upid[hsn_code_number]", this.state.hsncode)
    formData.append("upid[heading]", this.state.heading)
    formData.append("upid[sub_heading]",this.state.subheading)
    formData.append("upid[description]", this.state.productDescription1)
    formData.append("upid[cgst]", this.state.cgst)
    formData.append("upid[sgst]", this.state.sgst)
    formData.append("upid[igst]", this.state.igst)
    formData.append("upid[unit_of_measurement]", "test")
    formData.append("upid[secondary_unit_of_measurement]", "test")
    formData.append("upid[industry_market_id]",this.state.brandNamesList4[0].id.toString())
    formData.append("upid[application_group_id]",this.state.brandNamesList5[0].id.toString() )
    formData.append("upid[application_id]",this.state.brandNamesList6[0].id.toString() )
    formData.append("upid[logistical_code_id]",this.state.brandNamesList7[0].id.toString() )
    formData.append("upid[upid_name]",this.state.upid )

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addUPIDCall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     configJSON.addUPIDApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;


  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, fieldName: keyof S): void => {
    const { value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };
  handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      supportContact: event.target.value,
    });
  };

  handleTabChange = (newValue: number): void => {
    this.setState({ value: newValue});
  }
  handleTabChange1 = (newValue: number): void => {
    this.setState({ value1: newValue});
  }

  // Customizable Area End
}
