import React, { useState, ChangeEvent } from 'react';
import { Box, Drawer, Grid, IconButton, styled } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import { FilterBox } from './Filter';

interface IFilter {
  handleToggleSidebar: () => void;
  catBlock: boolean;
}

const FilterCatalogue: React.FC<IFilter> = (props) => {
  const [priceRangeValue, setPriceRangeValue] = useState<[number, number]>([0, 100]);

  const valuetext = (value: number) => {
    return `$ ${value}`;
  };

  const handleChange1 = (
    event: ChangeEvent<{}>,
    newValue: number | number[]
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    const minDistance = 10;
    const [newStart, newEnd] = newValue;

    const activeThumb = priceRangeValue[0] === newStart ? 0 : 1;

    if (activeThumb === 0) {
      setPriceRangeValue([
        Math.min(newStart, priceRangeValue[1] - minDistance),
        priceRangeValue[1],
      ]);
    } else {
      setPriceRangeValue([
        priceRangeValue[0],
        Math.max(newEnd, priceRangeValue[0] + minDistance),
      ]);
    }
  };

  return (
    <>
      <MasterFinances>
        <IconButton onClick={props.handleToggleSidebar}>
          <Menu />
        </IconButton>
      </MasterFinances>
      <Grid item>
        <CategoryMenu>
          <Drawer
            open={props.catBlock}
            anchor="left"
            onClose={props.handleToggleSidebar}
          >
            <FilterBox valuetext={valuetext} handleChange1={handleChange1} handleToggelBar={props.handleToggleSidebar}/>
          </Drawer>
        </CategoryMenu>
        <CategoryBLock>
          <FilterBox valuetext={valuetext} handleChange1={handleChange1} handleToggelBar={props.handleToggleSidebar}/>
        </CategoryBLock>
      </Grid>
    </>
  );
};

export default FilterCatalogue;


const MasterFinances = styled(Box)({
    display: "none !important",

    "@media (max-width: 600px)": {
        display: "block !important",
    },
    "@media only screen and (min-width: 600px) and (max-width: 960px) ": {
        display: "block !important",
        marginLeft: "15px"
    },

});

const CategoryMenu = styled(Box)({
    display: "none",
    "@media (max-width: 600px)": {
        display: "block !important",
    },
    "@media only screen and (min-width: 600px) and (max-width: 960px) ": {
        display: "block !important",
    },
});

const CategoryBLock = styled(Box)({
    display: "block",
    margin: "30px 30px 0px 30px",

    height: "100%",
    "@media (max-width: 600px)": {
        display: "none !important",
    },
    "@media only screen and (min-width: 600px) and (max-width: 960px) ": {
        display: "none !important",
    },
});
