import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../../packages/framework/src/IBlock";
import { Message } from "../../../../packages/framework/src/Message";
import { runEngine } from "../../../../packages/framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, { getName } from "../../../../packages/framework/src/Messages/MessageEnum";
import { MouseEvent } from 'react';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  history: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  anchorEl: HTMLElement | null;
  totalResults: number;
  resultperPage: number;
  currentPage: number;
  open: boolean;
  open1: boolean;
  selectedBrands: { [key: string]: boolean };
  selectedOption: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ListProductVariationController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [];

    this.state = {
      anchorEl: null,
      totalResults: 140,
      resultperPage: 12,
      currentPage: 1,
      open: false,
      selectedBrands: {
        Havells: false,
        "RR Kabel": true,
        LG: true,
        Bosch: false,
        Crompton: false,
        Polycab: false,
        Hitachi: true,
        Philips: false,
        BajajElectricals: false,
        Siemens: false,
      },
      selectedOption: "newest",
      open1: false
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  handleNavigate = (route?: string) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  handleMenuClick = (event: MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = (): void => {
    this.setState({ anchorEl: null });
  };
  handlePageChange = (event: any, page: any) => {
    this.setState({ currentPage: page });
  };
  handleApply = () => {
    this.handleToggle();
  };

  handleRadioChange = (option: string) => {
    this.setState({ selectedOption: option });
  };

  handleToggle1 = () => {
    this.setState((prevState) => ({ open1: !prevState.open1 }));
  };
  handleToggle = () => {
    this.setState((prevState) => ({ open: !prevState.open }));
  };
  handleClearAll = () => {
    const clearedBrands = Object.keys(this.state.selectedBrands).reduce(
      (acc, brand) => {
        acc[brand] = false;
        return acc;
      },
      {} as { [key: string]: boolean }
    );
    this.setState({ selectedBrands: clearedBrands });
  };
  handleCheckboxChange = (brand: string) => {
    this.setState((prevState) => ({
      selectedBrands: {
        ...prevState.selectedBrands,
        [brand]: !prevState.selectedBrands[brand],
      },
    }));
  };
  // Customizable Area End
}
