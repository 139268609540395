import React from "react";

import {
  Box,
  Typography,
  Grid,
  Hidden
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles";
import { imgDown } from "./assets";

const GeneralBlock = styled("div")(({ theme }) => ({
   paddingLeft:"24px",
   cursor:"default",
   "& .input": {
    width: "97%",
    height: "44px",
    borderRadius: "8px",
    pointerEvents: "none",
    backgroundColor: "#F3F4F6", 
    color: "#BCC1CA", 
    border: "1px solid transparent",
    marginTop: "10px",
    padding: "10px",
    fontSize:"16px",
    fontWeight:400,
    boxShadow:"0 0 0 1000px #F3F4F6 inset !important"
   },
   "& .label-text": {
    fontWeight: 700,
    fontSize: "14px",
    color:"#272727"
   },
   "& .input-edit": {
    width: "97%",
    height: "44px",
    borderRadius: "8px",
    border: "1px solid transparent",
    marginTop: "10px",
    padding: "10px",
    fontSize:"16px",
    fontWeight:400,
    backgroundColor: "#F3F4F6",
    boxShadow:"0 0 0 1000px #F3F4F6 inset !important"
   },
   "& .comm-input": {
    border: "1px solid transparent",
    marginTop: "10px",
    padding: "10px",
    width: "97%",
    height: "44px",
    borderRadius: "8px",
    pointerEvents: "none",
    backgroundColor: "#F3F4F6", 
    boxShadow:"0 0 0 1000px #F3F4F6 inset !important",
    color: "#888", 
   },
   "& .input:focus": {
    borderColor:"#FF6600",
    outline: "none", 
   },
   "& .comm-input-edit": {
    marginTop: "10px",
    padding: "10px",
    width: "97%",
    height: "44px",
    border: "1px solid transparent",
    backgroundColor: "#F3F4F6", 
    borderRadius: "8px",
    boxShadow:"0 0 0 1000px #F3F4F6 inset !important"
   },
   "& .comm-text": {
    fontSize: "16px",
    fontWeight: 600,
    color:"#475569"
   },
   "& .comm-input[type=number]::-webkit-inner-spin-button,.comm-input[type=number]::-webkit-outer-spin-button": { 
    "-webkit-appearance": "none",
    "-moz-appearance": "none",
    "appearance": "none",
    margin: 0,
  },
  "& .comm-input[type=number]" :{
    "-moz-appearance": "textfield",
  },
  "& .comm-input-edit[type=number]::-webkit-inner-spin-button,.comm-input-edit[type=number]::-webkit-outer-spin-button": { 
    "-webkit-appearance": "none",
    "-moz-appearance": "none",
    "appearance": "none",
    margin: 0,
  },
  "& .comm-input-edit[type=number]" :{
    "-moz-appearance": "textfield",
  },
  '& .dropdown-list-language': {
    display: "flex",
    justifyContent:"space-between",
    flexDirection: "row",
    alignItems:"center",
    width: "93% !important",
    borderRadius: "8px",
    border: "1px solid transparent",
    marginTop: "10px",
    backgroundColor: "#F3F4F6",
    minHeight: "44px",
    "@media (max-width:900px)": {
      maxWidth:"74%",
    },
  },
  '& .dropdown-list-language-disable': {
    color: "#BCC1CA", 
    display: "flex",
    justifyContent:"space-between",
    flexDirection: "row",
    alignItems:"center",
    width: "93% !important",
    minHeight: "44px",
    borderRadius: "8px",
    border: "1px solid transparent",
    marginTop: "10px",
    pointerEvents: "none",
    backgroundColor: "#F3F4F6",
    "@media (max-width:900px)": {
      maxWidth:"74%",
    }, 
  },

  "& .dropdown-list-data-language li": {
    padding: "5px",
    margin:"7px 0px"
  },
  "& .dropdown-list-data-language": {
    position: "absolute",
    maxHeight: "400px",
    top: "67px !important",
    backgroundColor:"#FFFFFF",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    listStyle: "none",
    transition: "all 0.3s ease-out",
    fontSize: "16px",
    zIndex: 100,
    padding: "5px",
    cursor: "pointer",
    width: "638px !important",
    "@media (max-width:900px)": {
      width:"65vw !important",
      marginRight:"15vw"
    },
  },
  "& .dropdown-list-data-language li:last-of-type a": {
    border: "none",
  },
  "& .dropdown-item:hover": {
    backgroundColor: "#FFE0CC !important",
    borderRadius:"8px !important",
  },
  "& .error-msg": {
    color: "red",
    marginTop: "5px",
    fontSize: "12px"
  },
  }))
// Customizable Area End

import GeneralInfoBuyerController, {
  Props,
} from "./GeneralInfoBuyerController";

export default class GeneralInfoBuyer extends GeneralInfoBuyerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() { 
    const comItems = [
      { label: "Pvt Ltd" },
      { label: "Co Ltd" },
    ];
    return (
      // Customizable Area Start
      <GeneralBlock>
        <Grid container style={{ marginTop:"35px"}} spacing={2}>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={10}>
            <Typography className="label-text">Company Name<span style={{ color: "red"}}>*</span></Typography>
            <input data-test-id="company-name" id="company" placeholder="Realty Craft"
            value={this.state.generalData.companyname}
             className={this.props.isEdit ? "input-edit" : "input"}
              type="text" name="companyname" onChange={this.handleGeneralInfoChange }></input>
            <Typography className="error-msg">{this.props.fieldRequiredData.companyname}</Typography>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Box style={{position:"relative"}}>
                <Typography className="label-text">Company Type</Typography>
                <input data-test-id="company-type" id="companyType" placeholder="LLP"
            value={this.state.generalData.companytype}
             className={this.props.isEdit ? "input-edit" : "input"}
              type="text" name="companytype" onChange={this.handleGeneralInfoChange }></input>
                {/* <Box data-test-id="company-type-btn" className={this.props.isEdit ? 'dropdown-list-language' : "dropdown-list-language-disable"} onClick={this.handleDropdownCom}>
                    <Typography style={{ marginLeft:"5px", fontSize:"14px"}}>{this.state.selectedComOption}</Typography>
                    <img style={{ marginRight:"15px", mixBlendMode:"multiply"}} src={imgDown} alt="down_arrow" />
                </Box> */}
                  {this.props.isEdit ? (
                    <>
                        {this.state.showDropDownCom && (
                        <ul className="dropdown-list-data-language">
                            {comItems.map((item:{label: string}) => (
                            <li
                                key={item.label}
                                id="dropdowndata"
                                className="dropdown-item"
                                data-test-id="drop-company-type"
                                onClick={ () => this.handleComChange(item.label)}
                            >
                                <div>{item.label}</div>
                            </li>
                            ))}
                        </ul>
                        )}
                    </>
                  ) : ""}
                      
            </Box>
          </Grid>
        </Grid>

        <Grid container style={{ marginTop:"31px"}}>
          <Typography className="comm-text">Communication Details</Typography>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={10} style={{ display:"flex"}}>
          <Grid container spacing={2}>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <Box style={{ marginTop:"21px"}}>
              <Typography className="label-text">Contact Name<span style={{ color: "red"}}>*</span></Typography>
              <input data-test-id="contact-name" placeholder="John" id="company" className={this.props.isEdit ? "comm-input-edit" : "comm-input"} type="text" name="contact" value={this.state.generalData.contact} onChange={this.handleGeneralInfoChange }></input>
              <Typography className="error-msg">{this.props.fieldRequiredData.contact}</Typography>
            </Box>
            </Grid>

            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <Box style={{ marginTop:"21px"}}>
              <Typography className="label-text">Email<span style={{ color: "red"}}>*</span></Typography>
              <input data-test-id="email" id="company" placeholder="john@gmail.com" className={this.props.isEdit ? "comm-input-edit" : "comm-input"} type="text" name="email" value={this.state.generalData.email} onChange={this.handleGeneralInfoChange }></input>
              <Typography className="error-msg">{this.props.fieldRequiredData.email}</Typography>
            </Box>
            </Grid>

            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <Box style={{ marginTop:"21px"}}>
              <Typography className="label-text">Moblie Number<span style={{ color: "red"}}>*</span></Typography>
              <input data-test-id="mobile-number" id="company" placeholder="+4123475894" className={this.props.isEdit ? "comm-input-edit" : "comm-input"} type="number" name="phonenumber" value={this.state.generalData.phonenumber} onChange={this.handleGeneralInfoChange }></input>
              <Typography className="error-msg">{this.props.fieldRequiredData.phonenumber}</Typography>
            </Box>
            </Grid>
            </Grid>
          </Grid>
        </Grid>
      </GeneralBlock>
    
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End