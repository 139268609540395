import React from 'react';
import { Box, Chip } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { ChipDataItem } from '../../../packages/blocks/catalogue/src/ProductCatalogueController';

interface IChipList {
    shortChipData: ChipDataItem[],
    handlePropsDelete: (chipToDelete: { label: string; key: number; checked: boolean }) => void;
    delete: () => void;
}

export const ChipList = (props: IChipList) => {
    return (
        <Box style={{ display: "flex", gap: "10px", top: "10px", overflow: 'auto', width: "99%" }}>
            {props.shortChipData?.map((data: { label: string, key: number, checked: boolean }) => {
                return (
                    <Chip
                        key={data.key}
                        data-test-id="delete-btn"
                        label={data.label}
                        onDelete={() => { props.handlePropsDelete(data) }}
                        deleteIcon={<CloseIcon style={{ width: "15px", height: "15px", color: "black" }} />}
                    />
                )
            })}
            {props.shortChipData.length > 0 ? (
                <Chip
                    style={{ backgroundColor: "#FFF0E5" }}
                    data-test-id="delete-all"
                    label={"Clear all"}
                    onClick={props.delete}
                />) : ""}
        </Box>
    )
}