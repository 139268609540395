import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message"
import { getStorageData } from "../../../framework/src/Utilities";
import { Typography } from "@material-ui/core";
import React from "react";


// Customizable Area Start
export type DropdownOption = {
  id: number;
  name: string;
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  location:any
  history: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  value: number | 0;
  value1: number | 0;
  selectedStandard:any;
  upid: any;
  upidName: any;
  inputValue: any;
  versionId: any;
  productVersionName: any;
  alias: any;
  quantity: string;
  productSubcategory: any;
  productCategory: any;
  productDescription: string;
  selectedBrandId: any;
  hsncode: string;
  heading: string;
  subheading: string;
  productDescription1: string;
  cgst: any;
  sgst: any;
  igst: any;
  materialcomp: any;
  feature: string;
  key: string;
  unit: string;
  productcatag: any;
  productsubcatag: any;
  productType: string;
  physicalForm: string;
  productTypeOptions: any,
  physicalFormOptions: any,
  sampleAvail: string;
  unitOfMeasure: string;
  secondunitOfMeasure: string;
  inputAlias: any;
  unitConversion: string;
  pictureFile: File;
  brands: string;
  brandCollectName: string;
  standardPackage: string;
  masterPackage: string;
  colour: string;
  material: string;
  lengthInInch: any;
  widthInInch: any;
  InletSize: any;
  documents:any;
  thickness: any;
  brandProduct: string;
  brandSKU: string;
  standardPackage1: string;
  masterPackage1: string;
  IsNumber: string;
  productDescript: string;
  errors: any;
  cProductId: any;
  productId: any;
  certificates:any;
  documentFile: File;
  qualityFile1: File;
  qualityTest1: File;
  docTitle: string;
  standards: any
  features: any;
  certificateName: string;
  qualityTestName: string;
  brandData: any;
  error: string;
  pictureFileName: string;
  documentFileName: string;
  qualityTests:any;
  qualityFile1Name: string;
  userId: number;
  path:any;
  keys: string[];
  keys1: string[];
  keys2: string[];
  units: string[];
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddProductController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addDataCall: string | Message = ''
  addfeatureCall: string | Message = ''
  addbrandDetailsCall: string | Message = ''
  addstandardsCall: string | Message = ''
  adddescriptionCall: string | Message = ''
  adddetailSpecificationCall: string | Message = ''
  addimagesCall: string | Message = ''
  adddocumentsCall: string | Message = ''
  addcertificationCall: string | Message = ''
  getphysicalformApiCallId: any;
  getproducttypeApiCallId: any;
  getBrandsApiCallId: any;
  productListApiCallId: string | undefined;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedStandard:"",
      errors: "",
      materialcomp: [] as string[],
      documents: [
        { id: 1, docTitle: "", docType: "", documentFile: null, documentFileName: "" },
      ],
      certificates: [
        { id: 1, certificateName: "", qualityFile1: null, qualityFile1Name: "" },
      ],
      qualityTests: [
        { id: 1, qualityTestName: "", qualityFile2: null, qualityFile2Name: "" },
      ],
      cProductId: "",
      upidName: "",
      path:"",
      versionId: "",
      selectedBrandId: "",
      inputValue: "",
      brandData: [],
      value: 0,
      value1: 0,
      features: Array(8).fill(""),
      productCategory: "",
      standards: "",
      inputAlias: "",
      upid: "",
      productId: "",
      productVersionName: "",
      alias: [],
      quantity: "",
      productSubcategory: "",
      productDescription: "",
      productDescription1: "",
      hsncode: "",
      heading: "",
      subheading: "",
      cgst: "",
      sgst: "",
      igst: "",
      feature: "",
      key: "",
      unit: "",
      productcatag: "",
      productsubcatag: "",
      productType: "",
      physicalForm: "",
      productTypeOptions: [] as DropdownOption[],
      physicalFormOptions: [] as DropdownOption[],
      sampleAvail: "",
      unitOfMeasure: "",
      secondunitOfMeasure: "",
      unitConversion: "",
      pictureFile: new File([], ""),
      documentFile: new File([], ""),
      qualityFile1: new File([], ""),
      qualityTest1: new File([], ""),
      brands: "",
      brandCollectName: "",
      standardPackage: "",
      masterPackage: "",
      colour: "",
      material: "",
      lengthInInch: "",
      widthInInch: "",
      InletSize: "",
      thickness: "",
      brandProduct: "",
      brandSKU: "",
      standardPackage1: "",
      masterPackage1: "",
      IsNumber: "",
      productDescript: "",
      docTitle: "",
      certificateName: "",
      qualityTestName: "",
      error: "",
      pictureFileName: "",
      documentFileName: "",
      qualityFile1Name: "",
      userId: 0,
      keys: Array(8).fill(""),
      keys1: Array(10).fill(""),
      keys2: Array(15).fill(""),
      units: Array(15).fill(""),

      // Customizable Area End

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start  
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start  
    await super.componentDidMount();

    const previousRoute = this.props.location.state?.from || "Direct Visit";
    if (previousRoute === "/ProductList") {
      this.setState({value:1,path:previousRoute});
      this.getProductList(1);
    }
    this.getPhysicalForm("GET");
    this.getProductType('GET');
    this.getBrandsAPICall();
    const localStorageData = (typeof localStorage !== "undefined" && localStorage.getItem('user-id')) || '';

    const number = parseInt(localStorageData)
    const category = localStorage.getItem('CategoryId');
    const subcategory = localStorage.getItem('SubcategoryId');
    const CategoryName = localStorage.getItem('CategoryName');
    const SubcategoryName = localStorage.getItem('SubcategoryName');
    const selectedVersionName = localStorage.getItem('SelectedVersionName');
    const selectedUpidName = localStorage.getItem('SelectedUpidName');
    const selectedUpidId = localStorage.getItem('SelectedUpidId')
    const selectedVersionId = localStorage.getItem('SelectedVersionId')
    const ProductId = localStorage.getItem('ProductId');
   
    this.setState({
      userId: number,
      productcatag: category,
      productCategory: CategoryName,
      productsubcatag: subcategory,
      productVersionName: selectedVersionName,
      productSubcategory: SubcategoryName,
      upidName: selectedUpidName,
      upid: selectedUpidId,
      versionId: selectedVersionId,
      cProductId: ProductId
    })
    // Customizable Area End
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {

    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      if (apiRequestCallId === this.getphysicalformApiCallId) {
        this.handleGetPhysicalResponse(responseJson);
      }
      else if (apiRequestCallId === this.getproducttypeApiCallId) {
        this.handleGetProductResponse(responseJson);
      }
      else if (apiRequestCallId === this.addDataCall) {
        this.handleAddProductResponse(responseJson);
      }
      else if (apiRequestCallId === this.getBrandsApiCallId) {
        this.handleGetBrandProductResponse(responseJson);
      }
      else if (apiRequestCallId === this.productListApiCallId) {
        this.handleProductResponse(responseJson);
      }
      else if (apiRequestCallId === this.addcertificationCall) {
        this.handleCertificateResponse(responseJson);
      }

    }
  }

  handleCertificateResponse(responseJson:any){
    if(responseJson){
      this.navigateTo("/ProductList");
    }
  }
  handleProductResponse(responseJson: any) {
    if (responseJson?.product?.data) {
      this.setState({
        brandProduct:responseJson?.product?.data?.attributes.brand_product_name,
        brandSKU:responseJson?.product?.data?.attributes.brand_sku,
        standardPackage1:responseJson?.product?.data?.attributes.standard_packaging,
        masterPackage1:responseJson?.product?.data?.attributes.master_packaging,
        IsNumber:responseJson?.product?.data?.attributes.is_number,
        colour:responseJson?.product?.data?.attributes.colour,    
        material:responseJson?.product?.data?.attributes.material,    
        lengthInInch:responseJson?.product?.data?.attributes.length_in_inch,    
        widthInInch:responseJson?.product?.data?.attributes.width_in_inch,
        InletSize:responseJson?.product?.data?.attributes.inlet_size,    
        thickness:responseJson?.product?.data?.attributes.thickness,    
        productDescript:responseJson?.product?.data?.attributes.product_description_long,
        certificateName:responseJson?.product?.data?.attributes.certificates[0]?.name,
        qualityTestName:responseJson?.product?.data?.attributes.quality_tests[0]?.name,
        docTitle:responseJson?.product?.data?.attributes?.documents[0]?.title,
        selectedStandard:responseJson?.product?.data?.attributes?.standards||"IS",
        certificates:responseJson?.product?.data?.attributes?.certificates,
        qualityTests:responseJson?.product?.data?.attributes?.quality_tests,
        documents:responseJson?.product?.data?.attributes?.documents,
      });
      console.log("data incedd :",this.state.lengthInInch)
    }
  }
  
  handleGetBrandProductResponse(responseJson: any) {
    if (responseJson?.data) {
      this.setState({ brandData: responseJson?.data })
    }
  }
  handleAddProductResponse(responseJson: any) {
    if (responseJson?.data) {
      this.setState({ productId: responseJson?.data.id })
    }
  }

  handleGetProductResponse(responseJson: any) {
    if (responseJson?.data) {
      this.setState({
        productTypeOptions: responseJson.data.map((item: any) => ({
          id: item.attributes.id.toString(),
          name: item.attributes.name,
        })),
      });
    }
  }

  handleGetPhysicalResponse(responseJson: any) {
    if (responseJson?.data) {
      this.setState({
        physicalFormOptions: responseJson.data.map((item: any) => ({
          id: item.attributes.id.toString(),
          name: item.attributes.name,
        })),
      });
    }
  }

  handleDocumentChange = (index: number, field: string, value: any) => {
    this.setState((prevState) => {
      const updatedDocuments = [...prevState.documents]; 
      updatedDocuments[index] = {
        ...updatedDocuments[index],
        [field]: value, 
      };
      return {
        documents: updatedDocuments,
      };
    });
  };
  

  handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: string,
    index?: number,
    type?: "documents" | "certificates" | "qualityTests"
  ): void => {
    const file = event.target?.files?.[0];
    if (!file) return;
  
    this.setState((prevState) => {
      if (type && index !== undefined) {
        const updatedItems = [...(prevState[type] || [])];
        updatedItems[index] = {
          ...(updatedItems[index] || {}),
          [fieldName]: file,
          [`${fieldName}Name`]: file.name,
        };
        return { 
          ...prevState, 
          [type]: updatedItems, 
          errors: { ...prevState.errors, [`${type}[${index}].${fieldName}`]: undefined } 
        };
      }
  
      return { 
        ...prevState, 
        pictureFile: file, 
        pictureFileName: file.name 
      };
    });
  
    event.target.value = "";
  };
  

  addDocumentField = () => {
    this.setState((prevState) => ({
      documents: [
        ...prevState.documents,
        { id: prevState.documents.length + 1, docTitle: "", docType: "", documentFile: null, documentFileName: "" },
      ],
    }));
  };

  addCertificateField = () => {
    this.setState((prevState) => ({
      certificates: [...prevState.certificates, { certificateName: "", qualityFile1: null, qualityFile1Name: "" }]
    }));
  };
  addQualityTestField = () => {
    this.setState((prevState) => ({
      qualityTests: [...prevState.qualityTests, { qualityTestName: "", qualityFile2: null, qualityFileName: "" }]
    }));
  };

  validateProductFields = (): boolean => {
    const errors: Record<string, string> = {};
    const {
      productType,
      physicalForm,
      materialcomp,
      productVersionName,
    } = this.state;

    if (!productType?.trim()) errors.productType = "Product Type is required.";
    if (!physicalForm?.trim()) errors.physicalForm = "Physical Form is required.";
    if (!materialcomp || materialcomp.length === 0 || !materialcomp.every((item: any) => item.trim())) {
      errors.materialcomp = "Material Composition is required.";
    }
    if (!productVersionName?.trim()) errors.productVersionName = "Product Version Name is required.";

    this.setState({ errors });

    return Object.keys(errors).length === 0;
  };

  handleSubmit = () => {
    if (this.validateProductFields()) {
      this.setState({ value: 1 })
      this.postAddDataApi();
     
    } 
  };

  validateBrandDetailsFields = (): boolean => {
    const errors: Record<string, string> = {};
    const {
      brandProduct,
      brandSKU,
      standardPackage1,
      masterPackage1,
    } = this.state;

    if (!brandProduct?.trim()) errors.brandProduct = "Brand Product Name is required.";
    if (!brandSKU?.trim()) errors.brandSKU = "Brand SKU Number is required.";
    if (!standardPackage1?.trim()) errors.standardPackage1 = "Standard Packaging is required.";
    if (!masterPackage1?.trim()) errors.masterPackage1 = "Master Packaging is required.";

    if (!this.state.selectedBrandId) errors.selectedBrand = "Brand is required.";
    this.setState({ errors });

    return Object.keys(errors).length === 0;
  };

  validateProductDescription = (): boolean => {
    const errors: Record<string, string> = {};
    const { productDescript } = this.state;

    if (!productDescript?.trim()) {
      errors.productDescript = "Product Description is required.";
    }
    this.setState({ errors });

    return Object.keys(errors).length === 0;
  };
  handleSubmits1 = () => {

    if (this.validateProductDescription()) {
      this.setState({ value: 5 });
      this.productDescriptionApi();
    }
  };

  handleSubmits = () => {
    if (this.validateBrandDetailsFields()) {
      this.setState({ value: 3 })
      this.brandDetailsApi();
    }
  };

  handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && this.state.inputAlias.trim()) {
      const newAliases = [...this.state.alias, this.state.inputAlias.trim()];
      this.setState({ alias: newAliases, inputAlias: "" });
    }
  };  

  handleKeyDown2 = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && (this.state.inputValue?.trim() || "").length > 0) { 
      const newMaterials = [...this.state.materialcomp, this.state.inputValue.trim()];
      this.setState({ materialcomp: newMaterials, inputValue: "" });
    }
  };
  

  renderError = (field: any) => {
    if (this.state.errors?.[field]) {
      return (
        <Typography style={{ color: "red", fontSize: "12px" }}>
          {this.state.errors[field]}
        </Typography>
      );
    }
    return null;
  };
  handleTaxChange = (e: React.ChangeEvent<HTMLElement>, taxType: "cgst" | "sgst" | "igst") => {
    const target = e.target as HTMLInputElement;
    const inputValue = target.value.replace(/\D/g, "");
    if (inputValue === "") {
      this.setState({ [taxType]: "" } as any);
    } else {
      const numericValue = parseInt(inputValue, 10);
      if (!isNaN(numericValue) && numericValue <= 100) {
        this.setState({ [taxType]: numericValue } as any);
      }
    }
  };


  getTaxValue = (taxType: "cgst" | "sgst" | "igst") => {
    return this.state[taxType] !== null && this.state[taxType] !== undefined ? `${this.state[taxType]}%` : "";
  };

  validateFields = () => {
    const errors: any = {};

    if (!String(this.state.lengthInInch || "").trim()) {
      errors.lengthInInch = "Length is required.";
    }
    if (!String(this.state.widthInInch || "").trim()) {
      errors.widthInInch = "Width is required.";
    }
    if (!String(this.state.InletSize || "").trim()) {
      errors.InletSize = "Inlet size is required.";
    }
    if (!String(this.state.thickness || "").trim()) {
      errors.thickness = "Thickness is required.";
    }

    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };


  handleSubmit1 = () => {
    if (this.validateFields()) {
      this.setState({ value: 2 });
      this.postfeatureApi();
    }
  };

  getBrandsAPICall = async () => {
    const authToken = await getStorageData('authToken');

    const header = {
      token: authToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBrandsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBrandsEndPoints
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  ;

  handleBrandChange = (event: any, brands: any) => {
    if (brands) {
      this.setState({ selectedBrandId: brands.id })
    }
  };

  postAddDataApi = async () => {
    const token = await getStorageData('authToken');

    const header = {
      token: token,
    };

    const formData = new FormData();
    formData.append("product[product_identifier]", this.state.upid)
    formData.append("product[upid_id]", this.state.upid)
    formData.append("product[upid_version_id]", this.state.versionId)
    formData.append("product[product_version_name]", this.state.productVersionName)
    formData.append("product[alias][]", this.state.alias);
    formData.append("product[category_id]", this.state.productcatag)
    formData.append("product[sub_category_id]", this.state.productsubcatag)
    formData.append("product[product_description]", this.state.productDescription)
    formData.append("product[product_type]", this.state.productType)
    formData.append("product[physical_form]", this.state.physicalForm)
    formData.append("product[material_composition][]", this.state.materialcomp)
    formData.append("product[sample_availability]", this.state.sampleAvail)
    formData.append("product[hsn_code_number]", this.state.hsncode)
    formData.append("product[heading]", this.state.heading)
    formData.append("product[sub_heading]", this.state.subheading)
    formData.append("product[description]", this.state.productDescription1)
    formData.append("product[cgst]", this.state.cgst)
    formData.append("product[sgst]", this.state.sgst)
    formData.append("product[igst]", this.state.igst)
    formData.append("product[unit_of_measurement]", this.state.unitOfMeasure)
    formData.append("product[secondary_unit_of_measurement]", this.state.secondunitOfMeasure)
    formData.append("product[unit_conversion]", this.state.unitConversion)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addDataCall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addProductApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;

  }

  postfeatureApi = async () => {
    const token = await getStorageData('authToken');

    const header = {
      token: token,
    };
    const formData = new FormData();
    formData.append("product[brand_name]", this.state.brands);
    formData.append("product[brand_collection_name]", this.state.brandCollectName);
    formData.append("product[standard_packaging]", this.state.standardPackage);
    formData.append("product[master_packaging]", this.state.masterPackage);
    formData.append("product[colour]", this.state.colour);
    formData.append("product[material]", this.state.material);
    formData.append("product[length_in_inch]", this.state.lengthInInch);
    formData.append("product[width_in_inch]", this.state.widthInInch);
    formData.append("product[inlet_size]", this.state.InletSize);
    formData.append("product[thickness]", this.state.thickness);
    formData.append("context", 'features');


    const requestMessage1 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addfeatureCall = requestMessage1.messageId;
    requestMessage1.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_productdescription/products/${this.state.productId|this.state.cProductId}`
    );

    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage1.id, requestMessage1);

    return true;


  }

  brandDetailsApi = async () => {
    const token = await getStorageData('authToken');

    const header = {
      token: token,
    };
    const formData = new FormData();
    formData.append("product[brand_name]", '134');
    formData.append("product[brand_collection_name]", '1');
    formData.append("product[brand_product_name]", this.state.brandProduct);
    formData.append("product[brand_sku]", this.state.brandSKU);
    formData.append("product[standard_packaging]", this.state.standardPackage1);
    formData.append("product[master_packaging]", this.state.masterPackage1);
    formData.append("context", 'brand_details');
    formData.append("product[brand_id]", this.state.selectedBrandId);


    const requestMessage2 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addbrandDetailsCall = requestMessage2.messageId;
    requestMessage2.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_productdescription/products/${this.state.productId|this.state.cProductId}`
    );

    requestMessage2.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage2.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage2.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage2.id, requestMessage2);

    return true;


  }

  standardsApi = async () => {
    const token = await getStorageData('authToken');

    const header = {
      token: token,
    };
    const formData = new FormData();
    formData.append("product[standards]", 'IS');
    formData.append("product[is_number]", this.state.IsNumber);
    formData.append("context", 'standards');

    const requestMessage3 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addstandardsCall = requestMessage3.messageId;
    requestMessage3.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_productdescription/products/${this.state.productId|this.state.cProductId}`
    );

    requestMessage3.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage3.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage3.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage3.id, requestMessage3);

    return true;


  }

  productDescriptionApi = async () => {
    const token = await getStorageData('authToken');

    const header = {
      token: token,
    };
    const formData = new FormData();
    formData.append("product[product_description_long]", this.state.productDescript);
    formData.append("context", 'product_descriptions');

    const requestMessage4 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.adddescriptionCall = requestMessage4.messageId;
    requestMessage4.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_productdescription/products/${this.state.productId|this.state.cProductId}`
    );

    requestMessage4.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage4.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage4.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage4.id, requestMessage4);
    return true;
  }

  detailSpecificationApi = async () => {
    const token = await getStorageData('authToken');

    const header = {
      token: token,
    };
    const formData = new FormData();
    formData.append("context", 'specifications');
    formData.append("product[specification][Brand Details][0][feture]", 'true');
    formData.append("product[specification][Brand Details][0][key]", 'Brand');
    formData.append("product[specification][Brand Details][0][value]", 'select');
    formData.append("product[specification][Brand Details][1][feture]", 'false');
    formData.append("product[specification][Brand Details][1][key]", 'ISIN');
    formData.append("product[specification][Brand Details][1][value]", 'Select');
    formData.append("product[specification][General Properties][0][feture]", 'true');
    formData.append("product[specification][General Properties][0][key]", 'Material');
    formData.append("product[specification][General Properties][0][value]", 'Plastic');
    formData.append("product[specification][General Properties][1][feture]", 'false');
    formData.append("product[specification][General Properties][1][key]", 'Weight');
    formData.append("product[specification][General Properties][1][value]", '2kg');

    const requestMessage5 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.adddetailSpecificationCall = requestMessage5.messageId;
    requestMessage5.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_productdescription/products/${this.state.productId|this.state.cProductId}`
    );

    requestMessage5.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage5.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage5.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage5.id, requestMessage5);
    return true;
  }


  imagesApi = async () => {
    const token = await getStorageData('authToken');

    const header = {
      token: token,
    };
    const formData = new FormData();
    formData.append("product[images][]", this.state.pictureFile);
    formData.append("product[primary_image]", this.state.pictureFile);
    formData.append("context", 'images');

    const requestMessage6 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addimagesCall = requestMessage6.messageId;
    requestMessage6.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_productdescription/products/${this.state.productId|this.state.cProductId}`
    );

    requestMessage6.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage6.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage6.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage6.id, requestMessage6);
    return true;
  }

  documentsApi = async () => {
    const token = await getStorageData('authToken');

    const header = {
      token: token,
    };
    const formData = new FormData();
    this.state.documents.forEach((doc:any, index:any) => {
      formData.append(`product[documents_attributes][${index}][title]`, doc.docTitle);
      formData.append(`product[documents_attributes][${index}][document_type]`, doc.docType);
      formData.append(`product[documents_attributes][${index}][file]`, doc.documentFile);
    });
    formData.append("context", "documents");

    const requestMessage7 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.adddocumentsCall = requestMessage7.messageId;
    requestMessage7.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_productdescription/products/${this.state.productId|this.state.cProductId}`
    );

    requestMessage7.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage7.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage7.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage7.id, requestMessage7);
    return true;
  }

  navigateTo = (route: string) => {
    window.location.href = route;

  };

  certificationApi = async () => {
    const token = await getStorageData('authToken');

    const header = {
      token: token,
    };
    const formData = new FormData();
    this.state.certificates.forEach((certificate:any, index:any) => {
      formData.append(`product[certificates_attributes][${index}][name]`, certificate.certificateName);
      formData.append(`product[certificates_attributes][${index}][file]`, certificate.qualityFile1);
    });
  
    this.state.qualityTests.forEach((qualityTest:any, index:any) => {
      formData.append(`product[quality_tests_attributes][${index}][name]`, qualityTest.qualityTestName);
      formData.append(`product[quality_tests_attributes][${index}][file]`, qualityTest.qualityFile2);
    });
    formData.append("context", 'certification');

    const requestMessage8 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addcertificationCall = requestMessage8.messageId;
    requestMessage8.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_productdescription/products/${this.state.productId|this.state.cProductId}`
    );

    requestMessage8.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage8.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage8.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage8.id, requestMessage8);
  
    return true;
  }

  handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> |
      React.ChangeEvent<{ name?: string; value: unknown }>,
    fieldName: keyof S
  ): void => {
    const { value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [fieldName]: value,
      errors: { ...prevState.errors, [fieldName]: undefined },
    }));
  };

  handleChanges = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    type: "certificates" | "qualityTests"
  ) => {
    const { value } = event.target;
  
    this.setState((prevState) => {
      const updatedItems = [...prevState[type]]; 
  
      updatedItems[index] = {
        ...updatedItems[index], 
        certificateName: type === "certificates" ? value : updatedItems[index]?.certificateName,
        qualityTestName: type === "qualityTests" ? value : updatedItems[index]?.qualityTestName,
      };
  
      return { 
        ...prevState, 
        [type]: updatedItems 
      };
    });
  };
  
  
  handleTabChange = (newValue: number): void => {
    this.setState({ value: newValue });
  }
  handleTabChange1 = (newValue: number): void => {
    this.setState({ value1: newValue });
  }

  
  handleKeyChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    index: number,
    stateKey: keyof Pick<S, "keys" | "keys1" | "keys2" | "units">
  ) => {
    const updatedArray = [...(this.state[stateKey] as string[])];
    updatedArray[index] = e.target.value;
    this.setState({ [stateKey]: updatedArray } as Pick<S, typeof stateKey>);
  };

  getPhysicalForm = async (method: string) => {
    const header = {

      token: await getStorageData("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getphysicalformApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getphysicalformEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getProductType = async (method: string) => {
    const header = {
      token: await getStorageData("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getproducttypeApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getproducttypeEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getProductList = async (page = 1) => {
    const token = await getStorageData('authToken');
    
    const header = {
      "Content-Type": configJSON.productListApiContentType,
      token:token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.productListApiCallId = requestMessage.messageId;
    const endPoint = `${configJSON.getProductByIdApiEndPoint}/${this.state.cProductId}`;
  

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  // Customizable Area End
}
