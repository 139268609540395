
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../../packages/framework/src/IBlock";
import { Message } from "../../../../packages/framework/src/Message";
import { runEngine } from "../../../../packages/framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, { getName } from "../../../../packages/framework/src/Messages/MessageEnum";
export interface ProductCat {
    productId: string;
    productName: string;
    imageUrl: string | null;
    imageName: string | null;
    bestSeller: boolean;
    New: string; 
    status: string;
  }

export interface ChipDataItem {
    label: string;
    key: number;
    checked: boolean;
    value: string
  }
  
  
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  history: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  priceRangeValue:number[],
  catBlock:boolean;
  categoryId:  number | null,
  subCategoryId: number | null,
  productList: ProductCat[];
  idBrand: number | null;
  tokenData:string;
  showFilterData: boolean;
  chipData: ChipDataItem[];
  shortChipData: ChipDataItem[];
  chipString: string[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ProductCatalogueController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getProductApiCallId: any;
  getProductList: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [];

    this.state = {
        priceRangeValue:[15, 80],
        catBlock:false,
        categoryId:null,
        subCategoryId: null,
        productList: [{
          productName: "Huliot K233032 32 X 1 Mm", imageName: "hello img",
          productId: "11",
          imageUrl: null,
          bestSeller: true,
          New: "",
          status: ""
        },
        {
          productName: "Huliot K233033 32 X 1 Mm", imageName: "hello img",
          productId: "12",
          imageUrl: null,
          bestSeller: false,
          New: "new",
          status: "status"
        },
        {
          productName: "Huliot K233035 32 X 1 Mm", imageName: "hello hello",
          productId: "13",
          imageUrl: null,
          bestSeller: false,
          New: "",
          status: "best"
        },
        {
          productName: "Huliot K233037 32 X 1 Mm", imageName: "hello ts",
          productId: "11",
          imageUrl: null,
          bestSeller: false,
          New: "",
          status: ""
        },
        {
          productName: "Huliot K233036 32 X 2 Mm", imageName: "hello js",
          productId: "13",
          imageUrl: null,
          bestSeller: false,
          New: "new",
          status: "status"
        }],
        idBrand: null,
        tokenData:"",
        showFilterData:false,
        chipData: [
          { label: "Newest to Oldest", key: 0, checked: false, value: "updated_desc" },
          { label: "Oldest to Newest", key: 1, checked: false, value: "updated_asc" },
          { label: "Sort by A to Z", key: 2, checked: false, value: "name_asc" },
        ],
        shortChipData:[],
        chipString: [],
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  handleNavigate = (route?:string) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  handleToggleSidebar = () => {     
    this.setState({ catBlock: !this.state.catBlock});}

  getProductListData =  () => {
    return []
  }

  handleOpen = () => {
    this.setState({ showFilterData: !this.state.showFilterData })
  }
  handleDropdownClick = (data: { label: string, key: number, checked: boolean }, event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedChipData = this.state.chipData.map(item =>
      item.key === data.key ? { ...item, checked: event.target.checked } : item
    )

    const updatedShortChipData = updatedChipData.filter(item => item.checked)

    const dataValue = updatedShortChipData.map((data:ChipDataItem) => {
      return data.value
    })
  
    this.setState({
      chipData: updatedChipData,
      shortChipData: updatedShortChipData,
      chipString: dataValue
    },() => {
      this.getProductListData()
    });

  }

  handleDelete = (chipToDelete: { label: string, key: number, checked: boolean })  => {
    const updatedChipData = this.state.chipData.map(item =>
      item.key === chipToDelete.key ? { ...item, checked: false } : item
    )
    const deleteDataStore = this.state.shortChipData.filter((item: { label: string, key: number, checked: boolean }) => item.key !== chipToDelete.key);

    const deleteStringData = deleteDataStore.map((data: any) => {
      return data.value
    })
    this.setState({ chipData: updatedChipData, shortChipData: deleteDataStore, chipString: deleteStringData}, () => {
      this.getProductListData()
    })
  };
  delete = () => {
    this.setState({ shortChipData: [], chipData: this.state.chipData.map(item => ({ ...item, checked: false })), showFilterData: false, chipString:[] }, () => {
      this.getProductListData()
    });
  }
  // Customizable Area End
}
