import React from "react";


import {
    // Customizable Area Start
    Typography,
    Box,
    Button,
    Table,
    TableBody,
    TableContainer,
    TableRow,
    Dialog,
    DialogContent,
    styled,
    TextField,
    TableCell,
    IconButton,
    Menu,
    MenuItem,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import SideBar from "../../../components/src/SideBar.web"
import Navbar from "../../../components/src/Navbar.web";
import ApplicationController, { Props } from "./ApplicationController.web"
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Pagination, PaginationItem } from "@mui/material";
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowForwardIos';

const CompanyNameInput = styled(TextField)({
    width: "520px",
    height: "60px",
    borderRadius: "5px",
})

// Customizable Area End

export default class Application extends ApplicationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderDialogContentApplication = () => {
        const { dialogMode, industry } = this.state;

        let title = "";
        let inputField = null;

        if (dialogMode === "add") {
            title = "Add New Application";
            inputField = (
                <>
                    <Typography
                        style={{
                            fontSize: "14px",
                            fontWeight: "bolder",
                            color: "#334155",
                            paddingBottom: "4px",
                            fontFamily: "Inter",
                        }}
                    >
                        Application Group<span style={{ color: "red" }}> *</span>
                    </Typography>
                    <CompanyNameInput
                        aria-label="Industry Input"
                        data-testid="industry-input"
                        variant="outlined"
                        size="small"
                        value={this.state.applicationGroup}
                        InputProps={{
                            style: { borderRadius: "5px", border: "1px solid transparent" },
                        }}
                    />
                    <Typography
                        style={{
                            fontSize: "14px",
                            fontWeight: "bolder",
                            color: "#334155",
                            paddingBottom: "4px",
                            fontFamily: "Inter",

                        }}
                    >
                        Application <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <CompanyNameInput
                        aria-label="Industry Input"
                        data-testid="industry-input"
                        variant="outlined"
                        size="small"
                        placeholder="Enter Application "
                        value={industry}
                        onChange={this.handleIndustryMarket}
                        InputProps={{
                            style: { borderRadius: "5px", border: "1px solid transparent" },
                        }}
                    />
                </>
            );
        } else if (dialogMode === "edit") {
            title = "Edit Application";
            inputField = (
                <>
                    <Typography
                        style={{
                            fontSize: "14px",
                            fontWeight: "bolder",
                            color: "#334155",
                            paddingBottom: "4px",
                            fontFamily: "Inter",
                        }}
                    >
                        Application Group<span style={{ color: "red" }}> *</span>
                    </Typography>
                    <CompanyNameInput
                        aria-label="Industry Input"
                        data-testid="industry-input"
                        variant="outlined"
                        size="small"
                        placeholder="Edit Industry / Market"
                        value={this.state.applicationGroup}
                        InputProps={{
                            style: { borderRadius: "5px", border: "1px solid transparent" },
                        }}
                    />
                    <Typography
                        style={{
                            fontSize: "14px",
                            fontWeight: "bolder",
                            color: "#334155",
                            paddingBottom: "4px",

                        }}
                    >
                        Application <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <CompanyNameInput
                        aria-label="Industry Input"
                        data-testid="industry-input"
                        variant="outlined"
                        size="small"
                        placeholder="Enter Application"
                        value={industry}
                        onChange={this.handleIndustryMarket}
                        InputProps={{
                            style: { borderRadius: "5px", border: "1px solid transparent" },
                        }}
                    />


                    <Box
                        style={{
                            border: "1px solid #E2E8F0",
                            borderRadius: "8px",
                            padding: "16px",
                            backgroundColor: "#F8FAFC",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",

                        }}
                    >
                    </Box>

                </>
            );
        } else if (dialogMode === "delete") {
            title = "Delete Application";
            inputField = (
                <Typography
                    style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#334155",
                        paddingBottom: "4px",
                        marginTop: "62px",
                        marginLeft: '20px',
                        fontFamily: "Inter",
                    }}
                >
                    Are you sure you want to delete <b>{this.state.industry}</b> Application from Application?

                </Typography>
            );
        }

        return (
            <>
                <Box
                    display={"flex"}
                    style={{
                        cursor: "pointer",
                        justifyContent: "space-between",
                        margin: "5%",
                        marginBottom: "0px",
                        alignItems:"center"

                    }}
                    data-testid="close-dialog-button"
                    onClick={this.handleCloses}
                >
                    <Typography
                        style={{
                            fontWeight: "bolder",
                            fontSize: "30px",
                            color: "#272727",
                            fontFamily: "Inter",
                        }}
                    >
                        {title}
                    </Typography>
                    <CloseIcon />
                </Box>
                <DialogContent
                    style={{
                        lineHeight: "26px",
                        padding: "22px",
                        color: "#000000",
                        fontSize: "24px",
                        overflowX: "hidden",
                        overflowY: "hidden",
                        fontWeight: "bolder",

                    }}
                >
                    <div style={{ width: "100%", border: "1px solid #E2E8F0" }}></div>
                    <Box style={{ marginTop: "35px" }}>{inputField}</Box>
                    {this.state.alertVisible && (
                        <div data-testid="alert" className="alert">
                            {this.state.AlertMessage}
                        </div>
                    )}
                    {dialogMode !== "delete" && (
                        <Box
                            style={{
                                display: "flex",
                                justifyContent: "end",
                                marginTop: "19px",
                                gap: "20px",

                            }}
                        >
                            <Button
                                data-testid="action-button"
                                style={{
                                    height: "44px",
                                    borderRadius: "12px",
                                    width: "80px",
                                    border: "1px solid #FF6600",
                                    fontSize: "16px",
                                    backgroundColor: "#FF6600",
                                    gap: "8px",
                                    color: "white",
                                    fontWeight: "bolder",
                                    textTransform: "capitalize",
                                    fontFamily: "Inter",
                                }}
                                onClick={
                                    dialogMode === "add"
                                        ? this.addNewApplication
                                        : this.editApplication

                                }
                            >
                                {dialogMode === "add" ? "Add" : "Save"}
                            </Button>
                        </Box>
                    )}
                    {dialogMode === "delete" && (
                        <Box
                            style={{
                                display: "flex",
                                justifyContent: "right",
                                marginTop: "50px",
                                gap: "20px",
                                fontFamily: "Inter",
                            }}
                        >
                            <Button
                                data-testid="no-button"
                                style={{
                                    height: "44px",
                                    borderRadius: "12px",
                                    width: "70px",
                                    fontFamily: "Inter",
                                    fontSize: "16px",
                                    backgroundColor: "#E0E0E0",
                                    color: "#000000",
                                    fontWeight: "bolder",
                                    textTransform: "capitalize",
                                }}
                                onClick={this.handleCloses}
                            >
                                No
                            </Button>
                            <Button
                                data-testid="yes-button"
                                style={{
                                    height: "44px",
                                    borderRadius: "12px",
                                    width: "61px",
                                    fontFamily: "Inter",
                                    fontSize: "16px",
                                    backgroundColor: "#FF0000",
                                    gap: "8px",
                                    color: "white",
                                    fontWeight: "bolder",
                                    textTransform: "capitalize",
                                }}
                                onClick={this.deleteApplication}
                            >
                                Yes
                            </Button>
                        </Box>
                    )}
                </DialogContent>
            </>
        );
    };

    cartTableApplication = () => {
        const industryData1 = Array.isArray(this.state.industryData1) ? this.state.industryData1 : [];

        const rows2 = industryData1?.map((item, index) => (
            <TableRow key={`row-${index}`} style={{ borderBottom: "1px solid #E2E8F0", }} onClick={() => this.handleRowClick("/ApplicationProduct", item)} >
                <TableCell
                    style={{
                        padding: "12px",
                        fontSize: "14px",
                        color: "#475569",
                        fontWeight: "bold",
                        textAlign: "left",
                        fontFamily: "Inter",
                    }}
                >
                    {item?.attributes?.application_name}
                </TableCell>
                <TableCell
                    style={{
                        padding: "12px",
                        fontSize: "14px",
                        color: "#64748B",
                        fontWeight: "normal",
                        textAlign: "center",
                    }}
                >
                    {item?.attributes?.no_of_products || "-"}
                </TableCell>
                <TableCell
                    style={{
                        padding: "12px",
                        textAlign: "center",
                    }}
                >
                    <IconButton
                        aria-label="more"
                        onClick={(event) => {
                            event.stopPropagation()
                            this.handleMenuClick(event, item.id, item.attributes.application_name)
                        }
                        }
                        style={{ color: "#475569" }}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleMenuCloseApplication}
                        PaperProps={{
                            style: {
                                boxShadow: "none",
                                border: "1px solid #E7E2Ef",
                                width: "120px",
                            },
                        }}
                    >
                        <MenuItem
                            onClick={(event) => {
                                event.stopPropagation();
                                this.handleComOpens("edit")
                            }}
                            style={{
                                fontSize: "14px",
                                color: "#334155",
                            }}
                        >
                            Edit
                        </MenuItem>
                        <MenuItem
                            onClick={(event) => {
                                event.stopPropagation();
                                this.handleComOpens("delete")
                            }}
                            style={{
                                fontSize: "14px",
                                color: "#334155",
                            }}
                        >
                            Delete
                        </MenuItem>
                    </Menu>
                </TableCell>
            </TableRow>
        ));

        return (
            <Box style={{ width: "94%", ...webStyles.mainContainers }}>
                <Box style={webStyles.headers}>
                    <Typography
                        style={{
                            fontSize: "14px",
                            fontWeight: "bolder",
                            color: "#1E293B",
                            paddingBottom: "4px",
                            fontFamily: "Inter",

                        }}
                        onClick={() => this.navigateToo("/ApplicationIndustry")}
                    >
                        Industry / Market

                    </Typography>
                    <Typography style={{ marginLeft: "6px", marginBottom: "6px",fontFamily: "Inter", }}>
                        <ArrowBackIcon style={{ color: "#64748B", fontSize: "14px" }} />
                    </Typography>
                    <Typography style={{
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#1E293B",
                        paddingBottom: "4px",
                        marginLeft: "6px",
                        fontFamily: "Inter",

                    }}
                        onClick={() => this.navigateToo("/ApplicationGroup")}
                    >
                        {this.state.industryname1}
                    </Typography>
                    <Typography style={{ marginLeft: "6px", marginBottom: "6px",fontFamily: "Inter", }}>
                        <ArrowBackIcon style={{ color: "#64748B", fontSize: "14px" }} />
                    </Typography>
                    <Typography style={{ marginLeft: "6px", marginBottom: "5px",fontFamily: "Inter", color: "#565E6C", fontWeight: "normal", fontSize: "14px" }}>
                        {this.state.applicationGroup}
                    </Typography>

                </Box>
                <Box style={webStyles.header1}>
                    <Typography variant="h6" style={webStyles.heading1}>
                        {this.state.applicationGroup}
                    </Typography>
                    <Button
                        variant="contained"
                        data-testid="open-dialog-button"
                        onClick={() => this.handleComOpens("add")}
                        style={{
                            ...webStyles.checkoutButton1,
                            textTransform: "none",
                            boxShadow: "none",
                        }}
                    >
                        Add New Application
                    </Button>

                </Box>
                <Box style={{ border: "1px solid #E2E7F0", borderRadius: "8px" }}>
                    <TableContainer>
                        <Table>
                            <thead >
                                <tr style={{ backgroundColor: "#F1F5F9", borderBottom: "1px solid #E2E7F0" }}>
                                    <th
                                        style={{
                                            padding: "12px",
                                            textAlign: "left",
                                            color: "#475569",
                                            fontWeight: "bold",
                                            fontSize: "14px",
                                        }}
                                    >
                                        Applications
                                    </th>
                                    <th
                                        style={{
                                            padding: "12px",
                                            textAlign: "center",
                                            color: "#475569",
                                            fontWeight: "bold",
                                            fontSize: "14px",
                                        }}
                                    >
                                        No of Products
                                    </th>
                                    <th
                                        style={{
                                            padding: "12px",
                                            textAlign: "center",
                                            color: "#475569",
                                            fontWeight: "bold",
                                            fontSize: "14px",
                                        }}
                                    >
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <TableBody>
                                {rows2.length > 0 ? (
                                    rows2
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={3} style={{ textAlign: "center", padding: "20px" }}>
                                            No data available
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "10px 16px",
                            borderTop: "1px solid #E7E2F0",
                        }}
                    >
                        <Typography style={{ fontSize: "12px", fontWeight: "normal", color: "#64748B" }}>
                            {this.getResultTextApplication(this.state.currentPage)}
                        </Typography>
                        <Pagination
                            count={Math.ceil(this.state.totalResults1 / this.state.resultperPage1)}
                            page={this.state.currentPage}
                            onChange={this.handlePageChangeApplication}
                            siblingCount={0}
                            boundaryCount={1}
                            renderItem={(item) => (
                                <PaginationItem
                                    {...item}
                                    style={{
                                        textDecoration: "underline",
                                        borderRadius: 0,
                                        background: "none",
                                        fontSize: "12px",
                                        color: "#979797",
                                    }}
                                />
                            )}
                        />
                    </Box>
                </Box>
            </Box>
        );
    };


    // Customizable Area End

    render() {

        return (
            // Customizable Area Start
            <Box style={{ display: "flex", flexDirection: "column", gap: "15px", }}>
                <Navbar {...this.props} history={this.props.history} handleNavigate={this.handleNavigateApplication} />
                <SideBar mycart={true} navigation={this.props.navigation} data-testId="searchText">
                    {this.cartTableApplication()}
                </SideBar>

                <Dialog
                    open={this.state.openDialog}
                    onClose={this.handleCloses}
                    style={{ borderRadius: "50px" }}
                    PaperProps={{
                        style: { borderRadius: "8px", width: "600px", maxHeight: "80vh", overflowY: "scroll", },
                    }}
                    data-testid="dialog"
                >
                    <Box style={{ overflow: "auto", cursor: "pointer" }} >
                        {this.renderDialogContentApplication()}
                    </Box>

                </Dialog>


            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyles: {
    [key: string]: {
        [property: string]: string | number
    }
} = {
    mainContainers: {
        marginLeft: "47px",

    },
    header1: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "20px 0px",
        fontFamily: "Inter",
    },
    headers: {
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        margin: "20px 0px",
    },
    heading1: {
        fontSize: "24px",
        fontWeight: "bolder",
        lineHeight: "26px",
        textAlign: "left",
        fontFamily: "Inter",
    },
    checkoutButton1: {
        backgroundColor: "#ff6600",
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: "bold",
        lineHeight: "24px",
        color: "white",
        borderRadius: "13px",
        height: "44px",
        width: "max-content",
    },
    TableCell: {
        fontWeight: "bolder",
        fontSize: "14px",
        lineHeight: "22px",
        textAlign: "left",
        color: "#565E6C",
        backgroundColor: "#F3F4F6",
    },
    tableBody: {
        width: "1045px",
        padding: "15px",
    },


};

// Customizable Area End