import { Box, Checkbox, Grid, Slider, Typography } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import React, { ChangeEvent } from 'react';
import ArrowUp from '@material-ui/icons/KeyboardArrowUp';
interface IFilter {
    handleChange1:(  event: ChangeEvent<{}>,
        newValue: number | number[])=>void,
        valuetext:(value: number)=>string,
        handleToggelBar:()=>void
}
export const FilterBox = (props: IFilter) => {
    const conditions = [{name:'Condition 1', active:true}, {name:'Condition 2', active:false}]
    return (
        <>
            <CatBlock>
                <Box>
                    <Box>
                        <Box className="cat" style={{ margin: "20px 30px" }}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={props.handleToggelBar}>
                                <path d="M4.28516 7.85742L15.7137 7.85742" stroke="#171A1F" stroke-width="1.71429" stroke-miterlimit="10" stroke-linecap="square" />
                                <path d="M2.14453 3.57129L17.8588 3.57129" stroke="#171A1F" stroke-width="1.71429" stroke-miterlimit="10" stroke-linecap="square" />
                                <path d="M6.42969 12.1426L13.5725 12.1426" stroke="#171A1F" stroke-width="1.71429" stroke-miterlimit="10" stroke-linecap="square" />
                                <path d="M8.57031 16.4287H11.4275" stroke="#171A1F" stroke-width="1.71429" stroke-miterlimit="10" stroke-linecap="square" />
                            </svg>
                            <Typography className="cat-text">FILTERS</Typography>
                        </Box>
                        <Box className="filterSeparator" />
                        {conditions.map((elem, i) => {
                            return (
                                <div>
                                    <Box className="condition" style={{ margin: "20px 30px" }}>
                                        <Box className="firstLineCondition">
                                            <Typography className={elem.active ? "textCondition-active" : "textCondition"}>
                                                {elem.name}
                                            </Typography>
                                            <Box className="arrowCondition-active">
                                                <ArrowUp fontSize="large" />
                                            </Box>
                                        </Box>
                                        <Grid container direction="row" justifyContent="space-between">
                                            <Grid item xs={4} sm={5} md={5} lg={5} xl={5} justifyContent="center">
                                                <Box className="option">
                                                    <Checkbox className="option__checkbox" checked={true}></Checkbox>
                                                    <Typography className="option__text">Option 1</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={4} sm={5} md={5} lg={5} xl={5} justifyContent="center">
                                                <Box className="option">
                                                    <Checkbox className="option__checkbox" checked={false}></Checkbox>
                                                    <Typography className="option__text">Option 2</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={4} sm={5} md={5} lg={5} xl={5} justifyContent="center">
                                                <Box className="option">
                                                    <Checkbox className="option__checkbox" checked={false}></Checkbox>
                                                    <Typography className="option__text">Option 3</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={4} sm={5} md={5} lg={5} xl={5} justifyContent="center">
                                                <Box className="option">
                                                    <Checkbox className="option__checkbox" checked={false}></Checkbox>
                                                    <Typography className="option__text">Option 4</Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box className="filterSeparator" />
                                </div>
                            )
                        })}
                        <Box className="firstLineCondition" style={{ margin: "20px 30px" }}>
                            <Typography className="textRange">
                                Price Range
                            </Typography>
                            <Box className="arrowCondition-active">
                                <ArrowUp fontSize="large" />
                            </Box>
                        </Box>
                        <Box className="price" style={{ margin: "20px 30px" }}>
                            <textarea
                                placeholder="min"
                                className="price__input"
                                value={'$ 100'}
                            />
                            <textarea
                                placeholder="max"
                                className="price__input"
                            />
                        </Box>
                        <Box style={{ margin: "20px 30px" }}>
                            <Slider
                                getAriaLabel={() => 'Minimum distance'}
                                value={[15, 80]}
                                onChange={props.handleChange1}
                                valueLabelDisplay="auto"
                                getAriaValueText={props.valuetext}
                                className="priceSlider"
                            />
                        </Box>
                        <Box style={{ margin: "20px 30px", display: "flex", justifyContent: "center" }}>
                            <button className="applyBtn">
                                Apply
                            </button>
                        </Box>
                        <Box className="filterSeparator" />
                        <Box style={{ margin: "20px 30px", display: "flex", justifyContent: "center" }}>
                            <button className="applyBtn">
                                Clear All
                            </button>
                        </Box>
                    </Box>
                </Box>

            </CatBlock>
        </>
    )
}


const CatBlock = styled("div")({
    border: "1px solid #E2E8F0",
    borderRadius: "8px",

    "& .cat": {
        display: "flex"
    },
    "& .cat-text": {
        fontFamily: "Inter",
        fontWeight: 500,
        fontSize: "16px",
        color: "#171A1F",
        marginLeft: "16px",

    },
    "& .product-data": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    "& .box-icon": {
        width: "24px",
        height: "24px",
        color: "#64748B",
    },
    "& .MuiRadio-colorSecondary.Mui-checked": {
        color: "#FF6600"
    },
    "& .price-text": {
        fontSize: "24px",
        fontWeight: 600,
        color: "#000000"
    },
    "& .price-txt": {
        fontSize: "16px",
        fontWeight: 600,
        color: "#475569"
    },
    "& .MuiSlider-root": {
        color: "#FF6600"
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
        color: "#FF6600"
    },
    "& .MuiCheckbox-root": {
        color: "#64748B"
    },
    "& .check-txt": {
        fontSize: "16px",
        fontWeight: 400,
        color: "#64748B"
    },
    "& .filterSeparator": {
        width: "100%",
        border: "1px solid #E2E8F0",
        "@media (max-width: 600px)": {
           width:"98%"
        },
    },
    "& .firstLineCondition": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    "& .textCondition-active": {
        fontFamily: "Inter",
        fontWeight: 700,
        fontSize: "16px",
        color: "#171A1F",
    },
    "& .textCondition": {
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "16px",
        color: "#171A1F",
    },
    "& .textRange": {
        fontFamily: "Inter",
        fontWeight: 700,
        fontSize: "16px",
        color: "#171A1F",
    },
    "& .arrowCondition-active": {
        color: "#EE6920"
    },
    "& .option__text": {
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "14px",
        color: "#171A1F",
        whiteSpace: "nowrap"
    },
    "& .option": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "20px",
        "@media (max-width:1500px)": {
            justifyContent: "space-around",
        },

    },
    "& .PrivateSwitchBase-root-26": {
        padding: "0px"
    },
    "& .price": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    "& .price__input": {
        border: "1px solid #565E6C",
        borderRadius: "8px",
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "14px",
        color: "#171A1F",
        width: "90px",
        height: "40px",
        padding: "10px",
        resize: "none"
    },
    "& .priceSlider": {
        color: "#FF6600",
        height: 12,
    },
    "& .MuiSlider-track": {
        border: "none",
        height: "4px"
    },
    "& .MuiSlider-rail": {
        height: 4,
    },
    "& .MuiSlider-thumb": {
        height: 15,
        width: 15,
        backgroundColor: "#fff",
        border: "2px solid currentColor",
        "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
            boxShadow: "inherit",
        },
        "&::before": {
            display: "none",
        },
    },
    "& .applyBtn": {
        width: '80%',
        fontFamily: "Inter",
        fontWeight: 700,
        fontSize: "14px",
        color: "#FFF",
        padding: "4px 0px",
        lineHeight: "32px",
        borderRadius: "8px",
        borderColor: "transparent",
        backgroundColor: "#FF6600",
        cursor: "pointer"
    }
})