import React from "react";
import { Box, Checkbox, Grid, Hidden, Typography } from "@material-ui/core"
import { down } from "../../blocks/catalogue/src/assets";
import { ChipDataItem } from "../../../packages/blocks/catalogue/src/ProductCatalogueController";
import { styled } from "@mui/styles";
interface ISortByDrop {
    handleOpen: () => void,
    showFilterData: boolean;
    chipData: ChipDataItem[];
    handleDropdownClick: (data: { label: string, key: number, checked: boolean }, event: React.ChangeEvent<HTMLInputElement>) => void
}

export const SortByDropDown = (props: ISortByDrop) => {
    return <StyledGrid item className="short-boxes" >
        <Box className="short-boxes">
            <Box className="sort-data">
                <Box>
                    <Typography className="sortingText">Sort By:</Typography>
                </Box>
                <Box data-test-id="sort-btn" style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px", marginLeft: "2px", cursor: "pointer" }}
                    onClick={props.handleOpen}>
                    <Typography className="sortingText">Default</Typography>
                    <img src={down} alt="down" />
                </Box>
                <Hidden>
                    {props.showFilterData && (
                        <ul className="dropdown-list-data-language">
                            {props.chipData.map((item: { label: string, key: number, checked: boolean }) => (
                                <li
                                    key={item.label}
                                    id="dropdowndata"
                                    className="dropdown-item"
                                    style={{ display: "flex", alignItems: "center", justifyContent: "start" }}
                                    data-test-id="filter-data"
                                >
                                    <Checkbox style={{ width: "20px", borderRadius: "6px" }}
                                        checked={item.checked}
                                        data-test-id="check-btn"
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.handleDropdownClick(item, event)}
                                        className="check-btn" />
                                    <div style={{ fontFamily: 'Inter, sans-serif' }}>{item.label}</div>
                                </li>
                            ))}
                        </ul>
                    )}
                </Hidden>
            </Box>
        </Box>
    </StyledGrid>
}

const StyledGrid = styled(Grid)({
    "& .short-boxes": {
    display: "flex", justifyContent: "right",
    "@media (max-width:600px)": {
      justifyContent: "left !important"
    },
  },
    "& .sort-data": {
        display: "flex",
        width: "200px",
        height: "25px",
        alignItems: "flex-start",
        justifyContent: "center",
        position: "relative",
    },
    "& .dropdown-list-data-language": {
        fontFamily: "Red Hat Display",
        position: "absolute",
        maxHeight: "400px",
        top: "10px",
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
        listStyle: "none",
        transition: "all 0.3s ease-out",
        fontSize: "14px",
        fontWeight: 400,
        zIndex: 100,
        padding: "5px",
        cursor: "pointer",
        width: "190px",
        right: 10
    },
    "& .dropdown-list-data-language li": {
        padding: "0px 13px",
    },
    "& .dropdown-list-data-language li:last-of-type a": {
        border: "none",
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
        color: "#FF6600"
    },
    "& .MuiCheckbox-root": {
        color: "#CBD5E1"
    },
    "& .check-btn": {
        width: "20px",
        height: "20px",
        borderRadius: "6px",
    },
})